import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from './MaintenanceBanner.module.scss';
import {
    setDisplayUserBillMaintenanceRecords,
    useAppStateBillMaintenancePeriodSelector,
} from '../../features/systemAlerts/billMaintenancePeriodsSlice';
import { useAuthSelector } from '../../features/auth/authSlice';
import { useAppStateHeaderSelector } from '../header/headerSlice';

export default function MaintenanceBanner() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const billMaintenanceDivOnLoad = (bannerIsDisplayed: boolean) => {
        dispatch(setDisplayUserBillMaintenanceRecords(bannerIsDisplayed));
        return '';
    };

    const { userBillMaintenancePeriod } =
        useAppStateBillMaintenancePeriodSelector((state) => state);
    const { isAuthenticated, authUser } = useAuthSelector((x) => x);
    const { selectedLanguage } = useAppStateHeaderSelector((state) => state);

    const languageIsSpanish = () => {
        // If there is a manual language switch in the user session, make sure this is captured
        if (selectedLanguage && selectedLanguage !== '') return selectedLanguage === 'es';
        // If the user is logged in, get their language
        else if (authUser?.Language && authUser?.Language !== '') return authUser?.Language === 'es';
        // Otherwise, get language in the user's local storage
        return localStorage.getItem('language') === 'es';
    }

    return (
        <>
            {userBillMaintenancePeriod?.maintenanceSettings ? (
                <div
                    className={styles.billPayWarningOuterContainer}
                    style={{
                        backgroundColor: isAuthenticated ? '#f4f4f4' : 'white',
                    }}
                >
                    <div className={styles.billPayWarningInnerContainer}>
                        <div className={styles.billMaintenanceHeaderContainer}>
                            <i
                                className={`far fa-exclamation-triangle ${styles.alertHeaderIcon}`}
                            ></i>
                            <h3 id={styles.billingMaintenanceHeader}>
                                {t('Alert.MaintenanceTitleHeader')}
                            </h3>
                        </div>
                        <p className={styles.billingMaintenanceText}>
                            {languageIsSpanish()
                                ? userBillMaintenancePeriod.maintenanceSettings
                                      .spanishText
                                : userBillMaintenancePeriod.maintenanceSettings
                                      .englishText}
                        </p>
                        {billMaintenanceDivOnLoad(true)}
                    </div>
                </div>
            ) : (
                <div>{billMaintenanceDivOnLoad(false)}</div>
            )}
        </>
    );
}
