import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import { Button } from '../../components/button/Button';
import { PageContainer } from '../../components/pageContainer/PageContainer';
import { Panel } from '../../components/panel/Panel';
import { PanelRow } from '../../components/panel/PanelRow';
import { Spinner } from '../../components/spinner/Spinner';
import { Text } from '../../components/text/Text';
import { SalesforceKnowledgeArticle } from '../../models/salesforceKnowledgeArticle';
import { salesforceService } from '../../services/salesforceService';
import { useAuthSelector } from '../auth/authSlice';
import styles from './KnowledgeBase.module.scss';
import { firebaseService } from '../../services/firebaseService';

const DESKTOP_THRESHOLD = 1216;

export function KnowledgeBase({ location }: { location: Location }) {
    const { t } = useTranslation();
    const history = useHistory();
    const { isAuthenticated } = useAuthSelector((state) => state);
    const [isDesktop, setDesktop] = useState(
        window.innerWidth > DESKTOP_THRESHOLD,
    );

    const [searchTerm, setSearchTerm] = useState('');
    const [searchComplete, setSearchComplete] = useState(true);
    const [searchArticles, setSearchArticles] = useState<
        SalesforceKnowledgeArticle[]
    >([]);
    const [trendingArticles, setTrendingArticles] = useState<
        SalesforceKnowledgeArticle[]
    >([]);
    const [trendingFetched, setTrendingFetched] = useState(false);

    const loadSearchResults = async (searchTerm: string, page: number) => {
        setSearchComplete(false);
        setSearchArticles(
            await salesforceService.searchKnowledgebase(searchTerm, page),
        );
        firebaseService
            .app()
            .analytics()
            .logEvent('knowledgebase_search', { searchTerm, platform: 'web' });
        setSearchComplete(true);
    };

    const search = () => {
        loadSearchResults(searchTerm, 1);
        history.push({
            pathname: '/knowledgebase',
            search: `?term=${encodeURIComponent(searchTerm)}`,
        });
    };

    const onTextChange = (_e: any, data: { value: any }) => {
        setSearchTerm(data.value);
    };

    const onKeyDown = (e: { key: string }) => {
        if (e.key === 'Enter') {
            search();
        }
    };

    useEffect(() => {
        (async () => {
            setTrendingArticles(
                await salesforceService.getTrendingKnowledgePosts(),
            );
            setTrendingFetched(true);
        })();
    }, []);

    useEffect(() => {
        const originalVal = new URLSearchParams(location.search).get('term');

        if (!originalVal) {
            return;
        }

        setSearchTerm(originalVal || '');
        loadSearchResults(originalVal || '', 1);
    }, [location.search]);

    const updateMedia = () => {
        setDesktop(window.innerWidth > DESKTOP_THRESHOLD);
    };

    useEffect(() => {
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    }, []);

    return (
        <>
            <PageContainer
                className={!isAuthenticated ? styles.unauthenticatedView : ''}
            >
                <div className="headerContainer">
                    <h1 className={styles.heading}>{t('Home.FAQs')}</h1>
                </div>
                {isDesktop ? (
                    <PanelRow>
                        <Panel size={2} style={{ alignSelf: 'flex-start' }}>
                            <h2>Search Help Articles</h2>
                            <div className={styles.termWrapper}>
                                <Text
                                    onKeyDown={onKeyDown}
                                    value={searchTerm}
                                    onChange={onTextChange}
                                />
                                <Button onClick={search}>Search</Button>
                            </div>
                            <div>
                                {searchComplete ? (
                                    searchArticles && searchArticles.length ? (
                                        <>
                                            {searchArticles.map(
                                                (article, i) => {
                                                    return (
                                                        <div
                                                            key={i}
                                                            className={
                                                                styles.searchResult
                                                            }
                                                        >
                                                            <NavLink
                                                                to={`/knowledgebase/${article.id}`}
                                                                className={
                                                                    styles.titleLink
                                                                }
                                                            >
                                                                {article.title}
                                                            </NavLink>
                                                            <div className="styles.highlightDisplay">
                                                                {
                                                                    article.summary
                                                                }
                                                                ...
                                                                <br />
                                                                <NavLink
                                                                    to={`/knowledgebase/${article.id}`}
                                                                >
                                                                    Read More
                                                                </NavLink>
                                                            </div>
                                                        </div>
                                                    );
                                                },
                                            )}
                                        </>
                                    ) : history.location.search.length ? (
                                        <>No results found.</>
                                    ) : (
                                        <>Enter search criteria above.</>
                                    )
                                ) : (
                                    <Spinner isLoading={!searchComplete} />
                                )}
                            </div>
                        </Panel>
                        <Panel size={1} style={{ alignSelf: 'flex-start' }}>
                            <h2>Trending Articles</h2>
                            <div>
                                {trendingFetched ? (
                                    trendingArticles.length ? (
                                        trendingArticles.map((x, i) => {
                                            return <div key={i}>{x.title}</div>;
                                        })
                                    ) : (
                                        'No Trending Articles'
                                    )
                                ) : (
                                    <Spinner isLoading={!trendingFetched} />
                                )}
                            </div>
                        </Panel>
                    </PanelRow>
                ) : (
                    <>
                        <PanelRow>
                            <Panel size={2} style={{ alignSelf: 'flex-start' }}>
                                <h2>Search Help Articles</h2>
                                <div className={styles.termWrapper}>
                                    <Text
                                        onKeyDown={onKeyDown}
                                        value={searchTerm}
                                        onChange={onTextChange}
                                    />
                                    <Button onClick={search}>Search</Button>
                                </div>
                                <div>
                                    {searchComplete ? (
                                        searchArticles &&
                                        searchArticles.length ? (
                                            <>
                                                {searchArticles.map(
                                                    (article, i) => {
                                                        return (
                                                            <div
                                                                key={i}
                                                                className={
                                                                    styles.searchResult
                                                                }
                                                            >
                                                                <NavLink
                                                                    to={`/knowledgebase/${article.id}`}
                                                                    className={
                                                                        styles.titleLink
                                                                    }
                                                                >
                                                                    {
                                                                        article.title
                                                                    }
                                                                </NavLink>
                                                                <div className="styles.highlightDisplay">
                                                                    {
                                                                        article.summary
                                                                    }
                                                                    ...
                                                                    <br />
                                                                    <NavLink
                                                                        to={`/knowledgebase/${article.id}`}
                                                                    >
                                                                        Read
                                                                        More
                                                                    </NavLink>
                                                                </div>
                                                            </div>
                                                        );
                                                    },
                                                )}
                                            </>
                                        ) : history.location.search.length ? (
                                            <>No results found.</>
                                        ) : (
                                            <>Enter search criteria above.</>
                                        )
                                    ) : (
                                        <Spinner isLoading={!searchComplete} />
                                    )}
                                </div>
                            </Panel>
                        </PanelRow>
                        <PanelRow>
                            <Panel size={1} style={{ alignSelf: 'flex-start' }}>
                                <h2>Trending Articles</h2>
                                <div>
                                    {trendingFetched ? (
                                        trendingArticles.length ? (
                                            trendingArticles.map((x, i) => {
                                                return (
                                                    <div key={i}>{x.title}</div>
                                                );
                                            })
                                        ) : (
                                            'No Trending Articles'
                                        )
                                    ) : (
                                        <Spinner isLoading={!trendingFetched} />
                                    )}
                                </div>
                            </Panel>
                        </PanelRow>
                    </>
                )}
            </PageContainer>
        </>
    );
}
