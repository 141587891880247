import { AnyAction, Dispatch } from "redux";
import { setBillMaintenancePeriods, setDeleteModalOpen, setEditModalOpen, setLoading, setOnlineBillPayEnabled, setUserBillMaintenancePeriod } from "./billMaintenancePeriodsSlice";
import { openMessageModal } from "../../components/statusModal/messageModalActionCreators";
import { BillMaintenancePeriod } from "./billMaintenanceAppState";
import { axios } from "../../services/axiosService";

export const loadAdminBillMaintenancePeriods = () => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        const billingMaintenancePeriods: BillMaintenancePeriod[] = (await axios.get('/allPaymentMaintenanceInfo')).data;
        dispatch(setBillMaintenancePeriods(billingMaintenancePeriods));
        dispatch(loadCurrentBillMaintenancePeriodInfo() as unknown as AnyAction); // get the latest information for the "current online bill pay status"
        dispatch(setEditModalOpen(new Array(billingMaintenancePeriods.length).fill(false)));
        dispatch(setDeleteModalOpen(new Array(billingMaintenancePeriods.length).fill(false)));
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
};

export const loadCurrentBillMaintenancePeriodInfo = () => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        const userPaymentMaintenanceInfo = (await axios.get('/paymentMaintenanceInfo')).data;
        const billingMaintenancePeriod: BillMaintenancePeriod = {
            id: userPaymentMaintenanceInfo.maintenanceWarningAlert?.id,
            maintenanceWarningAlert: userPaymentMaintenanceInfo.maintenanceWarningAlert,
            maintenanceSettings: userPaymentMaintenanceInfo.maintenanceSettings,
        }
        dispatch(setUserBillMaintenancePeriod(billingMaintenancePeriod));
        dispatch(setOnlineBillPayEnabled(userPaymentMaintenanceInfo.onlineBillPayEnabled));
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
};

export const createBillingMaintenancePeriod = (
    billingMaintenancePeriod: BillMaintenancePeriod,
) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        (await axios.post(`/paymentMaintenanceInfo`, {
            billingMaintenancePeriod,
        }));
        await loadAdminBillMaintenancePeriods()(dispatch);
        // Display success message that alert was created
        dispatch(openMessageModal('add-system-alert', 'success') as unknown as AnyAction);
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
};

export const updateBillingMaintenancePeriod = (
    billingMaintenancePeriod: BillMaintenancePeriod,
) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        (await axios.put(`/paymentMaintenanceInfo`, {
            billingMaintenancePeriod,
        }));
        await loadAdminBillMaintenancePeriods()(dispatch);
        // Display success message that alert was updated
        dispatch(openMessageModal('update-system-alert', 'success') as unknown as AnyAction);
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
};

export const deleteBillingMaintenancePeriod = (id: number) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        (await axios.delete(`/paymentMaintenanceInfo/${id}`));
        await loadAdminBillMaintenancePeriods()(dispatch);
        // Display success message that alert was deleted
        dispatch(openMessageModal('delete-system-alert', 'success') as unknown as AnyAction);
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
};
