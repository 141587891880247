import React, { useEffect } from 'react';
import styles from './App.module.scss';
import { Header } from '../components/header/Header';
import { Routes } from '../routes';
import { Footer } from '../components/footer/Footer';
import { SideBarMenu } from '../components/sideBarMenu/SideBarMenu';
import { User } from '../models/user';
import { useTranslation } from 'react-i18next';
import { SemanticToastContainer } from 'react-semantic-toasts';
import { MessageModal } from '../components/statusModal/MessageModal';
import { useAppStateMessageModalSelector } from '../components/statusModal/messageModalViewSlice';
import { SystemAlert } from '../components/systemAlert/SystemAlert';
import { useDispatch } from 'react-redux';
import { loadUserAlerts } from '../features/systemAlerts/systemAlertsActionCreators';
import { getFeatureFlags } from '../features/featureFlags/featureFlagActionCreator';
import { useAppStateSystemAlertsListSelector } from '../features/systemAlerts/systemAlertsListSlice';
import { NotificationSettings } from '../components/notificationSettings/NotificationSettings';
import { useFeatureFlag } from '../features/featureFlags/FeatureFlag';
import CitibotChat from '../components/citiBot/CitibotChat';
import { authService } from '../services/authService';
import { useAppStateLoginSelector } from '../features/login/loginSlice';
import MaintenanceBanner from '../components/systemAlert/MaintenanceBanner';

export interface AppProps {
    isAuthenticated: boolean;
    authUser: User | null;
    selectedOrganization: string;
}

export const App: React.FunctionComponent<AppProps> = ({
    isAuthenticated,
    authUser,
    selectedOrganization,
}) => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const { loading, unreadAlertIndex } = useAppStateSystemAlertsListSelector(
        (state) => state,
    );
    const delinquentBillingNotificationsFF = useFeatureFlag(
        'EnableDelinquentBillingNotifications',
    );
    const { setUpdatingLoginRecord } = useAppStateLoginSelector(
        (state) => state,
    );

    useEffect(() => {
        // We don't have to explicitly fallback to 'en' because the i18n settings do that for us
        if (authUser?.Language) {
            i18n.changeLanguage(authUser.Language);
        } else {
            const storedLanguage = localStorage.getItem('language');
            if (storedLanguage) {
                i18n.changeLanguage(storedLanguage);
            }
        }

        // Record user session if the user hasn't just logged in
        if (authUser?.Id && !setUpdatingLoginRecord) {
            authService.recordUserSession(false);
        }

        // Load system alerts and bill maintenance periods
        dispatch(loadUserAlerts(t));

        // Fetch feature flags
        dispatch(getFeatureFlags(authUser?.Id));

    // eslint-disable-next-line
    }, [dispatch, i18n, authUser]);

    const isVerified = authUser?.Verified || false;

    // simple popup messages (alternative to toast messages)
    const { messageModalOpen } = useAppStateMessageModalSelector(
        (state) => state,
    );

    return (
        <div className={styles.app}>
            <Header isSignedIn={isAuthenticated} />
            <SystemAlert />
            <SemanticToastContainer position="top-right" />
            <MessageModal modalOpen={messageModalOpen} />
            <CitibotChat />
            <MaintenanceBanner />

            {isAuthenticated &&
            delinquentBillingNotificationsFF?.enabled &&
            !loading &&
            unreadAlertIndex === -1 ? (
                <NotificationSettings
                    userId={authUser?.Id!}
                    asModal={true}
                    viewOnly={false}
                />
            ) : null}

            {isAuthenticated && authUser!.SalesforceId ? (
                <div className={`${styles.authenticatedRouteContainer}`}>
                    <div className={styles.authenticatedScrollView}>
                        <SideBarMenu />
                        <div className={styles.routeContainer}>
                            <Routes
                                isAuthenticated={isAuthenticated}
                                selectedOrganization={selectedOrganization}
                                isVerified={isVerified}
                                hasSalesforceId
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.unauthenticatedRouteContainer}>
                    <Routes
                        isAuthenticated={isAuthenticated}
                        selectedOrganization={selectedOrganization}
                        isVerified={isVerified}
                    />
                </div>
            )}
            <Footer />
        </div>
    );
};
