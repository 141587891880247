import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from './SystemAlert.module.scss';
import { setUnreadAlertIndex, useAppStateSystemAlertsListSelector } from '../../features/systemAlerts/systemAlertsListSlice';
import { useAuthSelector } from '../../features/auth/authSlice';
import { storeUserAlertsArray } from '../../features/systemAlerts/systemAlertsActionCreators';
import { Modal } from 'semantic-ui-react';
import { Button } from '../button/Button';
import { useAppStateHeaderSelector } from '../header/headerSlice';

export const SystemAlert: React.FunctionComponent = () =>  {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const maintenanceWarningAlertColor = '#e8a317';
    const maintenanceWarningButtonColor = 'yellow';

    const { loading, userStoredAlerts, unreadAlertIndex } = useAppStateSystemAlertsListSelector(state => state);
    const { authUser } = useAuthSelector(x => x);
    const { selectedLanguage } = useAppStateHeaderSelector((state) => state);

    const languageIsSpanish = () => {
        // If there is a manual language switch in the user session, make sure this is captured
        if (selectedLanguage && selectedLanguage !== '') return selectedLanguage === 'es';
        // If the user is logged in, get their language
        else if (authUser?.Language && authUser?.Language !== '') return authUser?.Language === 'es';
        // Otherwise, get language in the user's local storage
        return localStorage.getItem('language') === 'es';
    }

    function dismissAlert() {
        // Mark this alert as read
        let arrCopy = [];
        for (let i = 0; i < userStoredAlerts.length; i++) {
            const alert = userStoredAlerts[i];
            if (i === unreadAlertIndex) {
                arrCopy.push({
                    ...alert,
                    Read: true
                });
            } else {
                arrCopy.push({
                    ...alert,
                });
            }
        }
        dispatch(storeUserAlertsArray(arrCopy));

        // Check if there is a next unread/active alert, if not set index to -1
        if (unreadAlertIndex+1 < userStoredAlerts.length && userStoredAlerts[unreadAlertIndex+1].Active) {
            dispatch(setUnreadAlertIndex(unreadAlertIndex+1));
        } else {
            dispatch(setUnreadAlertIndex(-1));
        }
    }

    return (
        <>
            {!loading && userStoredAlerts && unreadAlertIndex !== -1 &&
                <Modal open={true} onClose={() => dismissAlert()} size="mini" style={{
                        width: '50%',
                        margin: 'auto',
                        marginTop: '0px',
                        marginBottom: '0px',
                        maxWidth: '600px',
                        backgroundColor: 'white',
                        borderLeft: `10px solid ${userStoredAlerts[unreadAlertIndex]?.MaintenanceWarning ? maintenanceWarningAlertColor : '#e8a317'}`,
                    }}>
                        <div>
                            <div className={styles.alertInnerPanelContainer}>
                                <i
                                    className={`far fa-exclamation-triangle ${styles.alertHeaderIcon}`}
                                    style={userStoredAlerts[unreadAlertIndex]?.MaintenanceWarning ? { color: maintenanceWarningAlertColor } : {}}
                                ></i>
                                {languageIsSpanish() ?
                                    <h3
                                        className={styles.alertHeaderText}
                                        style={userStoredAlerts[unreadAlertIndex]?.MaintenanceWarning ? { color: maintenanceWarningAlertColor } : {}}
                                    >
                                        {userStoredAlerts[unreadAlertIndex]?.MaintenanceWarning ? t('Alert.MaintenanceWarningTitle') : userStoredAlerts[unreadAlertIndex].TitleAlertSpanish}
                                    </h3>
                                    :
                                    <h3
                                        className={styles.alertHeaderText}
                                        style={userStoredAlerts[unreadAlertIndex]?.MaintenanceWarning ? { color: maintenanceWarningAlertColor } : {}}
                                    >
                                        {userStoredAlerts[unreadAlertIndex]?.MaintenanceWarning ? t('Alert.MaintenanceWarningTitle') : userStoredAlerts[unreadAlertIndex].TitleAlertEnglish}
                                    </h3>
                                }
                                <div id={styles.spacer}></div>
                            </div>
                            <div className={styles.alertRightInnerPanelContainer}>
                                <div className={styles.alertBody}>
                                    <div style={{fontSize: '120%'}}>
                                        {languageIsSpanish() ?
                                            <span dangerouslySetInnerHTML={{__html: userStoredAlerts[unreadAlertIndex] ? userStoredAlerts[unreadAlertIndex].TextSpanish: ''}} />
                                            : 
                                            <span dangerouslySetInnerHTML={{__html: userStoredAlerts[unreadAlertIndex] ? userStoredAlerts[unreadAlertIndex].TextEnglish: ''}} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={styles.alertInnerButtonContainer}>
                                <Button color={userStoredAlerts[unreadAlertIndex]?.MaintenanceWarning ? maintenanceWarningButtonColor : 'yellow'} onClick={() => dismissAlert()} style={styles.dismissAlert} biggerFont={true}>{t('Alert.Dismiss')}</Button>
                            </div>
                        </div>
                </Modal>
            }
        </>
    );
}