import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import styles from './SystemAlerts.module.scss';
import { Toggle } from '../../components/toggle/Toggle';
import { Textarea } from '../../components/textArea/Textarea';
import InputMask from 'react-input-mask';
import { Text } from '../../components/text/Text';
import { Dropdown, DropdownOptions } from '../../components/dropdown/Dropdown';
import { Checkbox } from '../../components/checkbox/Checkbox';
import { BillMaintenancePeriod } from './billMaintenanceAppState';
import {
    setAddModalOpen,
    setDeleteModalOpen,
    setEditModalOpen,
    useAppStateBillMaintenancePeriodSelector,
} from './billMaintenancePeriodsSlice';
import {
    createBillingMaintenancePeriod,
    deleteBillingMaintenancePeriod,
    updateBillingMaintenancePeriod,
} from './billMaintenanceActionCreators';
import { PayBillButton as ButtonV2 } from '../../components/button/PayBillButton';

interface BillMaintenanceModal {
    modalOpen: boolean;
    billingPeriod: BillMaintenancePeriod | null;
    index?: number;
}

/* All date/time/hour inputs here are in America/Chicago time */
export const BillMaintenanceModal = ({
    modalOpen,
    billingPeriod,
    index,
}: BillMaintenanceModal) => {
    const meridiemItems: DropdownOptions[] = [
        {
            key: 'AM',
            text: 'AM',
            value: 'AM',
        },
        {
            key: 'PM',
            text: 'PM',
            value: 'PM',
        },
    ];
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [step, setStep] = useState(0);

    const extractTimePart = (
        timeString: string | undefined,
        part: 'meridiem' | 'hours' | 'minutes',
    ): string => {
        if (!timeString) return '';
        let cleanTime =
            timeString.includes('AM') || timeString.includes('PM')
                ? timeString.split(' ')[0]
                : timeString;
        const [hours, minutes] = cleanTime.split(':');
        const formattedHours = hours.padStart(2, '0');
        switch (part) {
            case 'meridiem':
                const ampm = timeString.split(' ')[1];
                return ampm ? ampm : '';
            case 'hours':
                return formattedHours;
            case 'minutes':
                return minutes;
            default:
                return '';
        }
    };

    /* Values for the downtime warning modal */
    const [showDowntimeWarning, setShowDowntimeWarning] = useState(
        billingPeriod?.maintenanceWarningAlert
            ? billingPeriod?.maintenanceWarningAlert.enabled
            : false,
    );
    const [englishModalContent, setEnglishModalContent] = useState(
        billingPeriod?.maintenanceWarningAlert
            ? billingPeriod.maintenanceWarningAlert.englishText
            : '',
    );
    const [spanishModalContent, setSpanishModalContent] = useState(
        billingPeriod?.maintenanceWarningAlert
            ? billingPeriod.maintenanceWarningAlert.spanishText
            : '',
    );
    const [modalStartDate, setModalStartDate] = useState(
        billingPeriod?.maintenanceWarningAlert
            ? billingPeriod.maintenanceWarningAlert.startDate
            : '',
    );
    const [modalEndDate, setModalEndDate] = useState(
        billingPeriod?.maintenanceWarningAlert
            ? billingPeriod.maintenanceWarningAlert.endDate
            : '',
    );
    const [modalStartTime, setModalStartTime] = useState(
        billingPeriod?.maintenanceWarningAlert
            ? `${extractTimePart(
                  billingPeriod?.maintenanceWarningAlert?.startTime,
                  'hours',
              )}:${extractTimePart(
                  billingPeriod?.maintenanceWarningAlert?.startTime,
                  'minutes',
              )}`.trim()
            : '',
    );
    const [modalStartMeridiemNotation, setModalStartMeridiemNotation] =
        useState(
            billingPeriod?.maintenanceWarningAlert
                ? extractTimePart(
                      billingPeriod?.maintenanceWarningAlert?.startTime,
                      'meridiem',
                  ).trim()
                : meridiemItems[0].value,
        );
    const [modalEndTime, setModalEndTime] = useState(
        billingPeriod?.maintenanceWarningAlert
            ? `${extractTimePart(
                  billingPeriod?.maintenanceWarningAlert?.endTime,
                  'hours',
              )}:${extractTimePart(
                  billingPeriod?.maintenanceWarningAlert?.endTime,
                  'minutes',
              )}`.trim()
            : '',
    );
    const [modalEndMeridiemNotation, setModalEndMeridiemNotation] = useState(
        billingPeriod?.maintenanceWarningAlert
            ? extractTimePart(
                  billingPeriod?.maintenanceWarningAlert?.endTime,
                  'meridiem',
              ).trim()
            : meridiemItems[0].value,
    );
    const [modalCheckboxValues, setModalCheckboxValues] = useState<
        Record<string, boolean>
    >(
        billingPeriod?.maintenanceWarningAlert
            ? billingPeriod.maintenanceWarningAlert.daysActive
            : {
                  everyDay: true,
                  monday: false,
                  tuesday: false,
                  wednesday: false,
                  thursday: false,
                  friday: false,
                  saturday: false,
                  sunday: false,
              },
    );

    /* Values for downtime banner and active maintenance messages of online payments */
    const [enableBillMaintenance, setEnableBillMaintenance] = useState(
        billingPeriod?.maintenanceSettings
            ? billingPeriod?.maintenanceSettings.enabled
            : false,
    );
    const [englishMaintenanceMessage, setEnglishMaintenanceMessage] = useState(
        billingPeriod?.maintenanceSettings
            ? billingPeriod.maintenanceSettings.englishText
            : '',
    );
    const [spanishMaintenanceMessage, setSpanishMaintenanceMessage] = useState(
        billingPeriod?.maintenanceSettings
            ? billingPeriod.maintenanceSettings.spanishText
            : '',
    );
    const [maintenanceStartDate, setMaintenanceStartDate] = useState(
        billingPeriod?.maintenanceSettings
            ? billingPeriod.maintenanceSettings.startDate
            : '',
    );
    const [maintenanceEndDate, setMaintenanceEndDate] = useState(
        billingPeriod?.maintenanceSettings
            ? billingPeriod.maintenanceSettings.endDate
            : '',
    );
    const [maintenanceStartTime, setMaintenanceStartTime] = useState(
        billingPeriod?.maintenanceSettings
            ? `${extractTimePart(
                  billingPeriod?.maintenanceSettings?.startTime,
                  'hours',
              )}:${extractTimePart(
                  billingPeriod?.maintenanceSettings?.startTime,
                  'minutes',
              )}`.trim()
            : '',
    );
    const [
        maintenanceStartMeridiemNotation,
        setMaintenanceStartMeridiemNotation,
    ] = useState(
        billingPeriod?.maintenanceSettings
            ? extractTimePart(
                  billingPeriod?.maintenanceSettings?.startTime,
                  'meridiem',
              ).trim()
            : meridiemItems[0].value,
    );
    const [maintenanceEndTime, setMaintenanceEndTime] = useState(
        billingPeriod?.maintenanceSettings
            ? `${extractTimePart(
                  billingPeriod?.maintenanceSettings?.endTime,
                  'hours',
              )}:${extractTimePart(
                  billingPeriod?.maintenanceSettings?.endTime,
                  'minutes',
              )}`.trim()
            : '',
    );
    const [maintenanceEndMeridiemNotation, setMaintenanceEndMeridiemNotation] =
        useState(
            billingPeriod?.maintenanceSettings
                ? extractTimePart(
                      billingPeriod?.maintenanceSettings?.endTime,
                      'meridiem',
                  ).trim()
                : meridiemItems[0].value,
        );
    const [maintenanceCheckboxValues, setMaintenanceCheckboxValues] = useState<
        Record<string, boolean>
    >(
        billingPeriod?.maintenanceSettings
            ? billingPeriod.maintenanceSettings.daysActive
            : {
                  everyDay: true,
                  monday: false,
                  tuesday: false,
                  wednesday: false,
                  thursday: false,
                  friday: false,
                  saturday: false,
                  sunday: false,
              },
    );

    const { addModalOpen, editModalOpen, deleteModalOpen } =
        useAppStateBillMaintenancePeriodSelector((state) => state);

    const submit = () => {
        const billingMaintenancePeriod = {
            id: billingPeriod?.id,
            maintenanceWarningAlert: {
                enabled: showDowntimeWarning,
                englishText: englishModalContent,
                spanishText: spanishModalContent,
                startDate: modalStartDate ?? '',
                endDate: modalEndDate ?? '',
                startTime: `${modalStartTime} ${modalStartMeridiemNotation}`,
                endTime: `${modalEndTime} ${modalEndMeridiemNotation}`,
                daysActive: {
                    everyDay: modalCheckboxValues.everyDay ?? false,
                    monday: modalCheckboxValues.monday ?? false,
                    tuesday: modalCheckboxValues.tuesday ?? false,
                    wednesday: modalCheckboxValues.wednesday ?? false,
                    thursday: modalCheckboxValues.thursday ?? false,
                    friday: modalCheckboxValues.friday ?? false,
                    saturday: modalCheckboxValues.saturday ?? false,
                    sunday: modalCheckboxValues.sunday ?? false,
                },
            },
            maintenanceSettings: {
                enabled: enableBillMaintenance,
                englishText: englishMaintenanceMessage,
                spanishText: spanishMaintenanceMessage,
                startDate: maintenanceStartDate ?? '',
                endDate: maintenanceEndDate ?? '',
                startTime: `${maintenanceStartTime} ${maintenanceStartMeridiemNotation}`,
                endTime: `${maintenanceEndTime} ${maintenanceEndMeridiemNotation}`,
                daysActive: {
                    everyDay: maintenanceCheckboxValues.everyDay ?? false,
                    monday: maintenanceCheckboxValues.monday ?? false,
                    tuesday: maintenanceCheckboxValues.tuesday ?? false,
                    wednesday: maintenanceCheckboxValues.wednesday ?? false,
                    thursday: maintenanceCheckboxValues.thursday ?? false,
                    friday: maintenanceCheckboxValues.friday ?? false,
                    saturday: maintenanceCheckboxValues.saturday ?? false,
                    sunday: maintenanceCheckboxValues.sunday ?? false,
                },
            },
        };
        if (addModalOpen) {
            dispatch(createBillingMaintenancePeriod(billingMaintenancePeriod));
            resetState();
            dispatch(setAddModalOpen(false));
        } else if (index !== undefined && editModalOpen[index]) {
            dispatch(updateBillingMaintenancePeriod(billingMaintenancePeriod));
            closeAlertEditing();
        } else if (index !== undefined && deleteModalOpen[index]) {
            dispatch(deleteBillingMaintenancePeriod(billingPeriod!.id!));
            closeAlertDeleting();
        }
    };

    const next = () => {
        setStep(step + 1);
    };

    const back = () => {
        setStep(step - 1);
    };

    const checkDowntimeWarningInputValid = (): boolean => {
        if (showDowntimeWarning) {
            return (
                !!englishModalContent &&
                englishModalContent.trim() !== '' &&
                !!spanishModalContent &&
                spanishModalContent.trim() !== '' &&
                !!modalStartTime &&
                isValidTimeString(modalStartTime) &&
                !!modalEndTime &&
                isValidTimeString(modalEndTime) &&
                !!modalStartMeridiemNotation &&
                modalStartMeridiemNotation.trim() !== '' &&
                !!modalEndMeridiemNotation &&
                modalEndMeridiemNotation.trim() !== '' &&
                isEndTimeGreaterThanStartTime(
                    modalStartTime,
                    modalEndTime,
                    modalStartMeridiemNotation,
                    modalEndMeridiemNotation,
                ) &&
                Object.values(modalCheckboxValues).some((value) => value)
            );
        }
        return true;
    };

    const checkMaintenancePeriodInputValid = (): boolean => {
        return (
            typeof enableBillMaintenance !== 'undefined' &&
            enableBillMaintenance !== null &&
            !!englishMaintenanceMessage &&
            englishMaintenanceMessage.trim() !== '' &&
            !!spanishMaintenanceMessage &&
            spanishMaintenanceMessage.trim() !== '' &&
            !!maintenanceStartTime &&
            isValidTimeString(maintenanceStartTime) &&
            !!maintenanceEndTime &&
            isValidTimeString(maintenanceEndTime) &&
            !!maintenanceStartMeridiemNotation &&
            maintenanceStartMeridiemNotation.trim() !== '' &&
            !!maintenanceEndMeridiemNotation &&
            maintenanceEndMeridiemNotation.trim() !== '' &&
            isEndTimeGreaterThanStartTime(
                maintenanceStartTime,
                maintenanceEndTime,
                maintenanceStartMeridiemNotation,
                maintenanceEndMeridiemNotation,
            ) &&
            Object.values(maintenanceCheckboxValues).some((value) => value)
        );
    };

    // Checks if HH:mm is valid in hours (01-12) and minutes (00-59)
    function isValidTimeString(time: string): boolean {
        const timeRegex = /^(0[1-9]|1[0-2]):([0-5]\d)$/;
        return timeRegex.test(time);
    }

    // Checks if endTime is greater than startTime in HH:mm AM/PM format
    function isEndTimeGreaterThanStartTime(
        startTime: string,
        endTime: string,
        startMeridiemNotation: string,
        endMeridiemNotation: string,
    ): boolean {
        // Convert to 24-hour format based on AM/PM
        function convertTo24HourFormat(time: string, meridiem: string): number {
            let [hours, minutes] = time.split(':').map(Number);
            if (meridiem === 'AM') {
                if (hours === 12) {
                    hours = 0; // 12 AM is midnight (00:00)
                }
            } else if (meridiem === 'PM') {
                if (hours !== 12) {
                    hours += 12; // Convert PM to 24-hour format
                }
            }
            return hours * 60 + minutes; // time in minutes
        }

        const startTimeInMinutes = convertTo24HourFormat(
            startTime,
            startMeridiemNotation,
        );
        const endTimeInMinutes = convertTo24HourFormat(
            endTime,
            endMeridiemNotation,
        );
        return startTimeInMinutes <= endTimeInMinutes;
    }

    const close = () => {
        if (addModalOpen) {
            resetState();
            dispatch(setAddModalOpen(false));
        } else if (index !== undefined && editModalOpen[index]) {
            if (billingPeriod !== null) {
                resetState();
            }
            closeAlertEditing();
        } else if (index !== undefined && deleteModalOpen[index]) {
            closeAlertDeleting();
        }
    };

    const closeAlertEditing = () => {
        setStep(0);
        const arrCopy: boolean[] = [...editModalOpen];
        arrCopy.splice(index!, 1, false);
        dispatch(setEditModalOpen(arrCopy));
    };

    const closeAlertDeleting = () => {
        setStep(0);
        const arrCopy: boolean[] = [...deleteModalOpen];
        arrCopy.splice(index!, 1, false);
        dispatch(setDeleteModalOpen(arrCopy));
    };

    function setModalTitle(): string {
        if (addModalOpen) {
            return t('Alert.AddBillMaintenancePeriod');
        } else if (index !== undefined && editModalOpen[index]) {
            return t('Alert.EditBillMaintenancePeriod');
        } else if (index !== undefined && deleteModalOpen[index]) {
            return (
                t('Alert.DeleteBillMaintenancePeriod') +
                ' "' +
                billingPeriod?.maintenanceSettings?.englishText +
                '"?'
            );
        }
        return 'N/A';
    }

    const resetState = () => {
        setStep(0);
        setShowDowntimeWarning(
            billingPeriod?.maintenanceWarningAlert?.enabled ?? false,
        );
        setEnglishModalContent(
            billingPeriod?.maintenanceWarningAlert?.englishText ?? '',
        );
        setSpanishModalContent(
            billingPeriod?.maintenanceWarningAlert?.spanishText ?? '',
        );

        setModalStartTime(
            billingPeriod?.maintenanceWarningAlert
                ? `${extractTimePart(
                      billingPeriod.maintenanceWarningAlert.startTime,
                      'hours',
                  )}:${extractTimePart(
                      billingPeriod.maintenanceWarningAlert.startTime,
                      'minutes',
                  )}`.trim()
                : '',
        );
        setModalStartMeridiemNotation(
            billingPeriod?.maintenanceWarningAlert
                ? extractTimePart(
                      billingPeriod.maintenanceWarningAlert.startTime,
                      'meridiem',
                  ).trim()
                : meridiemItems[0].value,
        );

        setModalEndTime(
            billingPeriod?.maintenanceWarningAlert
                ? `${extractTimePart(
                      billingPeriod.maintenanceWarningAlert.endTime,
                      'hours',
                  )}:${extractTimePart(
                      billingPeriod.maintenanceWarningAlert.endTime,
                      'minutes',
                  )}`.trim()
                : '',
        );
        setModalEndMeridiemNotation(
            billingPeriod?.maintenanceWarningAlert
                ? extractTimePart(
                      billingPeriod.maintenanceWarningAlert.endTime,
                      'meridiem',
                  ).trim()
                : meridiemItems[0].value,
        );

        setModalCheckboxValues(
            billingPeriod?.maintenanceWarningAlert?.daysActive ?? {
                everyDay: true,
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
            },
        );

        setEnableBillMaintenance(
            billingPeriod?.maintenanceSettings?.enabled ?? false,
        );
        setEnglishMaintenanceMessage(
            billingPeriod?.maintenanceSettings?.englishText ?? '',
        );
        setSpanishMaintenanceMessage(
            billingPeriod?.maintenanceSettings?.spanishText ?? '',
        );

        setMaintenanceStartTime(
            billingPeriod?.maintenanceSettings
                ? `${extractTimePart(
                      billingPeriod.maintenanceSettings.startTime,
                      'hours',
                  )}:${extractTimePart(
                      billingPeriod.maintenanceSettings.startTime,
                      'minutes',
                  )}`.trim()
                : '',
        );
        setMaintenanceStartMeridiemNotation(
            billingPeriod?.maintenanceSettings
                ? extractTimePart(
                      billingPeriod.maintenanceSettings.startTime,
                      'meridiem',
                  ).trim()
                : meridiemItems[0].value,
        );

        setMaintenanceEndTime(
            billingPeriod?.maintenanceSettings
                ? `${extractTimePart(
                      billingPeriod.maintenanceSettings.endTime,
                      'hours',
                  )}:${extractTimePart(
                      billingPeriod.maintenanceSettings.endTime,
                      'minutes',
                  )}`.trim()
                : '',
        );
        setMaintenanceEndMeridiemNotation(
            billingPeriod?.maintenanceSettings
                ? extractTimePart(
                      billingPeriod.maintenanceSettings.endTime,
                      'meridiem',
                  ).trim()
                : meridiemItems[0].value,
        );

        setMaintenanceCheckboxValues(
            billingPeriod?.maintenanceSettings?.daysActive ?? {
                everyDay: true,
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
            },
        );
    };

    const handleCheckboxChange = (
        checkboxValues: Record<string, boolean>,
        setCheckboxValues: React.Dispatch<
            React.SetStateAction<Record<string, boolean>>
        >,
        day: keyof typeof checkboxValues,
        checked: boolean,
    ) => {
        setCheckboxValues((prev) => {
            let updatedValues = { ...prev, [day]: checked };

            if (day === 'everyDay') {
                // If everyDay is checked, uncheck all other days
                updatedValues = Object.keys(prev).reduce((acc, key) => {
                    acc[key as keyof typeof checkboxValues] =
                        key === 'everyDay' ? checked : !checked;
                    return acc;
                }, {} as typeof checkboxValues);
            } else {
                // Uncheck everyDay if any individual day is changed
                updatedValues.everyDay = false;

                // Ensure that we allow unchecking only if there's more than one day checked
                const checkedDays = Object.entries(updatedValues)
                    .filter(([key]) => key !== 'everyDay')
                    .filter(([_, val]) => val);

                // If no day is checked after the uncheck, keep selected day checked
                if (checkedDays.length === 0) {
                    updatedValues[day] = true;
                }
            }

            return updatedValues;
        });
    };

    return (
        <Modal
            open={modalOpen}
            size={
                index !== undefined && deleteModalOpen[index] ? 'mini' : 'large'
            }
        >
            <Modal.Header>
                <span className={styles.modalHeader}>{setModalTitle()}</span>
                <div style={{ float: 'right' }}>
                    <i
                        className={`fa fa-times ${styles.closeButtonDialog}`}
                        onClick={() => close()}
                    />
                </div>
            </Modal.Header>
            <Modal.Content>
                <div className={styles.modalContent}>
                    {/* Show settings for turning on modal warnings for maintenance */}
                    {step === 0 && (
                        <div>
                            {((index !== undefined && editModalOpen[index]) ||
                                addModalOpen) && (
                                <div className={styles.toggleContainer}>
                                    <h3>
                                        {t('Alert.ShowUpcomingDowntimeWarning')}
                                    </h3>
                                    <Toggle
                                        value={showDowntimeWarning}
                                        onChange={(
                                            e: React.SyntheticEvent<HTMLElement>,
                                            data: { checked: boolean },
                                        ) =>
                                            setShowDowntimeWarning(data.checked)
                                        }
                                        text={t(
                                            'Alert.EnableModalPopup',
                                        )}
                                    />
                                </div>
                            )}
                            {((index !== undefined &&
                                editModalOpen[index] &&
                                showDowntimeWarning) ||
                                (addModalOpen && showDowntimeWarning)) && (
                                <div className={styles.textEditorContainer}>
                                    <h3>
                                        {t(
                                            'Alert.UpcomingDowntimeWarningEnglishMessage',
                                        ) + '*'}
                                    </h3>
                                    <Textarea
                                        onChange={(
                                            e: React.ChangeEvent<HTMLTextAreaElement>,
                                        ) =>
                                            setEnglishModalContent(
                                                e.target.value,
                                            )
                                        }
                                        value={englishModalContent}
                                        placeholder={t(
                                            'Alert.EnterEnglishMessage',
                                        )}
                                    />
                                </div>
                            )}
                            {((index !== undefined &&
                                editModalOpen[index] &&
                                showDowntimeWarning) ||
                                (addModalOpen && showDowntimeWarning)) && (
                                <div className={styles.textEditorContainer}>
                                    <h3>
                                        {t(
                                            'Alert.UpcomingDowntimeWarningSpanishMessage',
                                        ) + '*'}
                                    </h3>
                                    <Textarea
                                        onChange={(
                                            e: React.ChangeEvent<HTMLTextAreaElement>,
                                        ) =>
                                            setSpanishModalContent(
                                                e.target.value,
                                            )
                                        }
                                        value={spanishModalContent}
                                        placeholder={t(
                                            'Alert.EnterSpanishMessage',
                                        )}
                                    />
                                </div>
                            )}
                            {((index !== undefined &&
                                editModalOpen[index] &&
                                showDowntimeWarning) ||
                                (addModalOpen && showDowntimeWarning)) && (
                                <div className={styles.textEditorContainer}>
                                    <h3>
                                        {t(
                                            'Alert.UpcomingDowntimeWarningDateSettings',
                                        )}
                                    </h3>

                                    <div
                                        className={styles.timeSettingContainer}
                                    >
                                        <div className={styles.hourContainer}>
                                            <p className={styles.hourText}>
                                                {t('Alert.StartDate')}
                                            </p>
                                            <span
                                                className={
                                                    styles.timeIntervalContainer
                                                }
                                            >
                                                <input
                                                    className={
                                                        styles.datepicker
                                                    }
                                                    aria-label="Date"
                                                    type="date"
                                                    onChange={(e) => setModalStartDate(e.target.value)}
                                                    value={modalStartDate}
                                                />
                                            </span>
                                        </div>

                                        <div className={styles.hourContainer}>
                                            <p className={styles.hourText}>
                                                {t('Alert.EndDate')}
                                            </p>
                                            <span
                                                className={
                                                    styles.timeIntervalContainer
                                                }
                                            >
                                                <input
                                                    className={
                                                        styles.datepicker
                                                    }
                                                    aria-label="Date"
                                                    type="date"
                                                    onChange={(e) => setModalEndDate(e.target.value)}
                                                    value={modalEndDate}
                                                />
                                            </span>
                                        </div>
                                    </div>

                                    <div
                                        className={styles.timeSettingContainer}
                                    >
                                        <div className={styles.hourContainer}>
                                            <p className={styles.hourText}>
                                                {t('Alert.StartTime') + '*'}
                                            </p>
                                            <span
                                                className={
                                                    styles.timeIntervalContainer
                                                }
                                            >
                                                <InputMask
                                                    mask="99:99"
                                                    value={modalStartTime}
                                                    placeholder="HH:mm"
                                                    maskChar={null}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>,
                                                    ) =>
                                                        setModalStartTime(
                                                            e.target.value,
                                                        )
                                                    }
                                                    className={
                                                        styles.timeInputField
                                                    }
                                                >
                                                    {(inputProps: any) => (
                                                        <Text {...inputProps} />
                                                    )}
                                                </InputMask>
                                                <div
                                                    className={
                                                        styles.timeInputField
                                                    }
                                                >
                                                    <Dropdown
                                                        value={
                                                            modalStartMeridiemNotation
                                                        }
                                                        placeholder="..."
                                                        options={meridiemItems}
                                                        onChange={(
                                                            e: React.SyntheticEvent<HTMLElement>,
                                                            data: {
                                                                value: string;
                                                            },
                                                        ) =>
                                                            setModalStartMeridiemNotation(
                                                                data.value,
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </span>
                                        </div>

                                        <div className={styles.hourContainer}>
                                            <p className={styles.hourText}>
                                                {t('Alert.EndTime') + '*'}
                                            </p>
                                            <span
                                                className={
                                                    styles.timeIntervalContainer
                                                }
                                            >
                                                <InputMask
                                                    mask="99:99"
                                                    value={modalEndTime}
                                                    placeholder="HH:mm"
                                                    maskChar={null}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>,
                                                    ) =>
                                                        setModalEndTime(
                                                            e.target.value,
                                                        )
                                                    }
                                                    className={
                                                        styles.timeInputField
                                                    }
                                                >
                                                    {(inputProps: any) => (
                                                        <Text {...inputProps} />
                                                    )}
                                                </InputMask>
                                                <div
                                                    className={
                                                        styles.timeInputField
                                                    }
                                                >
                                                    <Dropdown
                                                        value={
                                                            modalEndMeridiemNotation
                                                        }
                                                        placeholder="..."
                                                        options={meridiemItems}
                                                        onChange={(
                                                            e: React.SyntheticEvent<HTMLElement>,
                                                            data: {
                                                                value: string;
                                                            },
                                                        ) =>
                                                            setModalEndMeridiemNotation(
                                                                data.value,
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </span>
                                        </div>
                                    </div>

                                    <p
                                        className={`${styles.hourText} ${styles.scheduleHeaderText}`}
                                    >
                                        {t('Alert.ScheduleQuestion') + '*'}
                                    </p>
                                    <div>
                                        <Checkbox
                                            value={modalCheckboxValues.everyDay}
                                            text={t(`Alert.EveryDay`)}
                                            onChange={(
                                                e: React.SyntheticEvent,
                                                data: { checked: boolean },
                                            ) =>
                                                handleCheckboxChange(
                                                    modalCheckboxValues,
                                                    setModalCheckboxValues,
                                                    'everyDay',
                                                    data.checked,
                                                )
                                            }
                                        />
                                        {[
                                            'monday',
                                            'tuesday',
                                            'wednesday',
                                            'thursday',
                                            'friday',
                                            'saturday',
                                            'sunday',
                                        ].map((day) => (
                                            <Checkbox
                                                key={day}
                                                value={modalCheckboxValues[day]}
                                                text={t(
                                                    `Alert.${
                                                        day
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                        day.slice(1)
                                                    }`,
                                                )}
                                                onChange={(
                                                    e: React.SyntheticEvent,
                                                    data: { checked: boolean },
                                                ) =>
                                                    handleCheckboxChange(
                                                        modalCheckboxValues,
                                                        setModalCheckboxValues,
                                                        day,
                                                        data.checked,
                                                    )
                                                }
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                            {index !== undefined && deleteModalOpen[index] && (
                                <div className={styles.alertDeleteModalBody}>
                                    <p>
                                        {t(
                                            'Alert.BillMaintenanceConfirmationDeletion',
                                        )}
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
                    {/* Show settings for shutting off online bill pay for maintenance */}
                    {step === 1 && (
                        <div>
                            {((index !== undefined && editModalOpen[index]) ||
                                addModalOpen) && (
                                <div className={styles.toggleContainer}>
                                    <h3>
                                        {addModalOpen ? t(
                                            'Alert.ActivateDowntimeMaintenancePeriodUponCreation',
                                        ) : t('Alert.ActivateDowntimeMaintenancePeriod')}
                                    </h3>
                                    <Toggle
                                        value={enableBillMaintenance}
                                        onChange={(
                                            e: React.SyntheticEvent<HTMLElement>,
                                            data: { checked: boolean },
                                        ) =>
                                            setEnableBillMaintenance(
                                                data.checked,
                                            )
                                        }
                                        text={t(
                                            'Alert.ActivateDowntimeMaintenancePeriod',
                                        )}
                                    />
                                </div>
                            )}
                            {((index !== undefined && editModalOpen[index]) ||
                                addModalOpen) && (
                                <div className={styles.textEditorContainer}>
                                    <h3>{t('Alert.DowntimeEnglishMessage') + '*'}</h3>
                                    <Textarea
                                        onChange={(
                                            e: React.ChangeEvent<HTMLTextAreaElement>,
                                        ) =>
                                            setEnglishMaintenanceMessage(
                                                e.target.value,
                                            )
                                        }
                                        value={englishMaintenanceMessage}
                                        placeholder={t(
                                            'Alert.EnterEnglishMessage',
                                        )}
                                    />
                                </div>
                            )}
                            {((index !== undefined && editModalOpen[index]) ||
                                addModalOpen) && (
                                <div className={styles.textEditorContainer}>
                                    <h3>{t('Alert.DowntimeSpanishMessage') + '*'}</h3>
                                    <Textarea
                                        onChange={(
                                            e: React.ChangeEvent<HTMLTextAreaElement>,
                                        ) =>
                                            setSpanishMaintenanceMessage(
                                                e.target.value,
                                            )
                                        }
                                        value={spanishMaintenanceMessage}
                                        placeholder={t(
                                            'Alert.EnterSpanishMessage',
                                        )}
                                    />
                                </div>
                            )}
                            {((index !== undefined && editModalOpen[index]) ||
                                addModalOpen) && (
                                <div className={styles.textEditorContainer}>
                                    <h3>{t('Alert.DowntimeDateSettings')}</h3>

                                    <div
                                        className={styles.timeSettingContainer}
                                    >
                                        <div className={styles.hourContainer}>
                                            <p className={styles.hourText}>
                                                {t('Alert.StartDate')}
                                            </p>
                                            <span
                                                className={
                                                    styles.timeIntervalContainer
                                                }
                                            >
                                                <input
                                                    className={
                                                        styles.datepicker
                                                    }
                                                    aria-label="Date"
                                                    type="date"
                                                    onChange={(e) => setMaintenanceStartDate(e.target.value)}
                                                    value={maintenanceStartDate}
                                                />
                                            </span>
                                        </div>

                                        <div className={styles.hourContainer}>
                                            <p className={styles.hourText}>
                                                {t('Alert.EndDate')}
                                            </p>
                                            <span
                                                className={
                                                    styles.timeIntervalContainer
                                                }
                                            >
                                                <input
                                                    className={
                                                        styles.datepicker
                                                    }
                                                    aria-label="Date"
                                                    type="date"
                                                    onChange={(e) => setMaintenanceEndDate(e.target.value)}
                                                    value={maintenanceEndDate}
                                                />
                                            </span>
                                        </div>
                                    </div>

                                    <div
                                        className={styles.timeSettingContainer}
                                    >
                                        <div className={styles.hourContainer}>
                                            <p className={styles.hourText}>
                                                {t('Alert.StartTime') + '*'}
                                            </p>
                                            <span
                                                className={
                                                    styles.timeIntervalContainer
                                                }
                                            >
                                                <InputMask
                                                    mask="99:99"
                                                    value={maintenanceStartTime}
                                                    placeholder="HH:mm"
                                                    maskChar={null}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>,
                                                    ) =>
                                                        setMaintenanceStartTime(
                                                            e.target.value,
                                                        )
                                                    }
                                                    className={
                                                        styles.timeInputField
                                                    }
                                                >
                                                    {(inputProps: any) => (
                                                        <Text {...inputProps} />
                                                    )}
                                                </InputMask>
                                                <div
                                                    className={
                                                        styles.timeInputField
                                                    }
                                                >
                                                    <Dropdown
                                                        value={
                                                            maintenanceStartMeridiemNotation
                                                        }
                                                        placeholder="..."
                                                        options={meridiemItems}
                                                        onChange={(
                                                            e: React.SyntheticEvent<HTMLElement>,
                                                            data: {
                                                                value: string;
                                                            },
                                                        ) =>
                                                            setMaintenanceStartMeridiemNotation(
                                                                data.value,
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </span>
                                        </div>

                                        <div className={styles.hourContainer}>
                                            <p className={styles.hourText}>
                                                {t('Alert.EndTime') + '*'}
                                            </p>
                                            <span
                                                className={
                                                    styles.timeIntervalContainer
                                                }
                                            >
                                                <InputMask
                                                    mask="99:99"
                                                    value={maintenanceEndTime}
                                                    placeholder="HH:mm"
                                                    maskChar={null}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>,
                                                    ) =>
                                                        setMaintenanceEndTime(
                                                            e.target.value,
                                                        )
                                                    }
                                                    className={
                                                        styles.timeInputField
                                                    }
                                                >
                                                    {(inputProps: any) => (
                                                        <Text {...inputProps} />
                                                    )}
                                                </InputMask>
                                                <div
                                                    className={
                                                        styles.timeInputField
                                                    }
                                                >
                                                    <Dropdown
                                                        value={
                                                            maintenanceEndMeridiemNotation
                                                        }
                                                        placeholder="..."
                                                        options={meridiemItems}
                                                        onChange={(
                                                            e: React.SyntheticEvent<HTMLElement>,
                                                            data: {
                                                                value: string;
                                                            },
                                                        ) =>
                                                            setMaintenanceEndMeridiemNotation(
                                                                data.value,
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </span>
                                        </div>
                                    </div>

                                    <p
                                        className={`${styles.hourText} ${styles.scheduleHeaderText}`}
                                    >
                                        {t('Alert.ScheduleQuestion') + '*'}
                                    </p>
                                    <div>
                                        <Checkbox
                                            value={
                                                maintenanceCheckboxValues.everyDay
                                            }
                                            text={t(`Alert.EveryDay`)}
                                            onChange={(
                                                e: React.SyntheticEvent,
                                                data: { checked: boolean },
                                            ) =>
                                                handleCheckboxChange(
                                                    maintenanceCheckboxValues,
                                                    setMaintenanceCheckboxValues,
                                                    'everyDay',
                                                    data.checked,
                                                )
                                            }
                                        />
                                        {[
                                            'monday',
                                            'tuesday',
                                            'wednesday',
                                            'thursday',
                                            'friday',
                                            'saturday',
                                            'sunday',
                                        ].map((day) => (
                                            <Checkbox
                                                key={day}
                                                value={
                                                    maintenanceCheckboxValues[
                                                        day
                                                    ]
                                                }
                                                text={t(
                                                    `Alert.${
                                                        day
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                        day.slice(1)
                                                    }`,
                                                )}
                                                onChange={(
                                                    e: React.SyntheticEvent,
                                                    data: { checked: boolean },
                                                ) =>
                                                    handleCheckboxChange(
                                                        maintenanceCheckboxValues,
                                                        setMaintenanceCheckboxValues,
                                                        day,
                                                        data.checked,
                                                    )
                                                }
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <div className={styles.submitContainer}>
                        {step !== 0 && (
                            <div className={styles.buttonLeft}>
                                <ButtonV2
                                    onClick={back}
                                    color='white'
                                    longerButton={true}
                                >
                                    {t('Util.Back')}
                                </ButtonV2>
                            </div>
                        )}
                        <div className={!(index !== undefined && deleteModalOpen[index]) ? styles.buttonRight : ''}>
                            {index !== undefined && deleteModalOpen[index] ? (
                                // Delete button
                                <ButtonV2
                                    onClick={submit}
                                    color='red'
                                    longerButton={true}
                                >
                                    {t('Util.Yes')}
                                </ButtonV2>
                            ) : (
                                // Next page button
                                // OR save editing/adding button
                                <ButtonV2
                                    onClick={step === 0 ? next : submit}
                                    color='midnightBlue'
                                    longerButton={true}
                                    disabled={
                                        step === 0
                                            ? !checkDowntimeWarningInputValid()
                                            : !checkMaintenancePeriodInputValid()
                                    }
                                >
                                    {step === 0
                                        ? t('Util.Next')
                                        : t('Util.Save')}
                                </ButtonV2>
                            )}
                        </div>
                    </div>
                </div>
            </Modal.Content>
        </Modal>
    );
};
