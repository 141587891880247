import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '../../../components/pageContainer/PageContainer';
import { PanelRow } from '../../../components/panel/PanelRow';
import { Panel } from '../../../components/panel/Panel';
import styles from './WaterBillPaymentHistoryRecords.module.scss';
import moment from 'moment';
import { PayBillButton } from '../../../components/button/PayBillButton';
import { useDispatch } from 'react-redux';
import { loadBillingHistory, loadPaymentHistory } from '../../dashboard/dashboardActionCreators';
import { setBillingAccountSelectionModalOpen, useAppStateDashboardSelector } from '../../dashboard/dashboardSlice';
import { Spinner } from '../../../components/spinner/Spinner';
import { BillPayAccountSelection } from '../../../components/billPayAccountSelection/billPayAccountSelection';
import { setAccountNumber, setAccountType, setAmount } from '../paymentSlice';
import { NavLink, useHistory } from 'react-router-dom';
import { useAuthSelector } from '../../auth/authSlice';
import { DelinquentInformationResponse } from '../../../models/navilinePaymentHistory';
import { firebaseService } from '../../../services/firebaseService';
import { useAppStateBillMaintenancePeriodSelector } from '../../systemAlerts/billMaintenancePeriodsSlice';

export const WaterBillPaymentHistoryRecords = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { 
        billingHistory,
        loadingPaymentInformation,
        totalAmountDue,
        billingAccountSelectionModalOpen,
        paymentHistory,
        showAddBillingHelp,
        delinquentStatuses,
    } = useAppStateDashboardSelector(state => state);
    const { authUser } = useAuthSelector(state => state);

    const { onlineBillPayEnabled } =
        useAppStateBillMaintenancePeriodSelector((state) => state);

    const WaterBillPaymentRecordDesktop = (
        {
            type,
            fullAmount,
            currentAmount,
            paymentDate,
            dueDate,
            pdfLink,
            delinquentStatuses,
        }: 
        {
            type: string, 
            fullAmount: number,
            currentAmount: string,
            paymentDate: string,
            dueDate: string,
            pdfLink?: string,
            delinquentStatuses: DelinquentInformationResponse[],
        }) => {
        const { t } = useTranslation();

        const regex = /\(([^)]+)\)/;
        const matches = type.match(regex);

        let isDelinquent = false;
        if (delinquentStatuses.length) {
            isDelinquent = delinquentStatuses.some(status => {
                return status.accountNumber === matches![matches!.length-1];
            });
        }
    
        return (
            <tr className={styles.tableRow}>
                <td>
                    <div className={styles.billLinkContainer}>
                        <div className={pdfLink ? styles.billLinkText : styles.billNoLinkText}
                            onClick={() => {if(pdfLink) window.open(pdfLink, '_blank')}}
                        >
                            {t('Payment.WaterBill')} - {paymentDate}
                        </div>
                        <p className={styles.accountInformationText}>{type}</p>
                        {!isDelinquent &&
                            <p className={styles.dueDate}>{t('Payment.DueDate')}: {dueDate}</p>
                        }
                    </div>
                </td>
                <td>
                    <div className={styles.priceText}>{currentAmount}</div>
                </td>
                <td>
                    <div className={styles.singleButtonContainer}>
                        {pdfLink ?
                            <PayBillButton
                                color='white'
                                onClick={() => window.open(pdfLink, '_blank')}
                            >
                                {t('Payment.View')}
                            </PayBillButton>
                        :
                            <PayBillButton
                                color='whiteUnavailable'
                                disabled={true}
                            >
                                {t('Payment.BillUnavailable')}
                            </PayBillButton>
                        }
                    </div>
                </td>
            </tr>
        );
    };

    const WaterBillPaymentRecordMobile = (
        {
            type,
            fullAmount,
            currentAmount,
            paymentDate,
            dueDate,
            pdfLink,
            delinquentStatuses,
        }: 
        {
            type: string, 
            fullAmount: number,
            currentAmount: string,
            paymentDate: string,
            dueDate: string,
            pdfLink?: string,
            delinquentStatuses: DelinquentInformationResponse[],
        }) => {
        const { t } = useTranslation();

        const regex = /\(([^)]+)\)/;
        const matches = type.match(regex);

        let isDelinquent = false;
        if (delinquentStatuses.length) {
            isDelinquent = delinquentStatuses.some(status => {
                return status.accountNumber === matches![matches!.length-1];
            });
        }
    
        return (
            <div className={styles.paymentInfoContainer}>
                <div className={styles.mobilePriceText}>{currentAmount}</div>
                <div className={styles.mobileBillLinkText}>
                    <p className={pdfLink ? styles.billLinkText : styles.billNoLinkText} 
                        onClick={() => {if(pdfLink) window.open(pdfLink, '_blank')}}
                        >
                            {t('Payment.WaterBill')} - {paymentDate}
                    </p>
                    <p className={styles.accountInformationText}>{type}</p>
                    {!isDelinquent &&
                        <p className={styles.dueDate}>{t('Payment.DueDate')}: {dueDate}</p>
                    }
                </div>
                <div className={styles.singleButtonContainer}>
                    {pdfLink ?
                        <PayBillButton
                            color='white'
                            onClick={() => window.open(pdfLink, '_blank')}
                        >
                            {t('Payment.View')}
                        </PayBillButton>
                    :
                        <PayBillButton
                            color='whiteUnavailable'
                            disabled={true}
                        >
                            {t('Payment.BillUnavailable')}
                        </PayBillButton>
                    }
                </div>
            </div>
        );
    };

    const handleOnPayBills = () => {
        dispatch(setBillingAccountSelectionModalOpen(true));
    }

    const navigateToPaymentHistory = () => {
        history.push(`/payment/payment-history`);
    }    

    const handleOnPayBillsSelection = (customerId: string, locationId: string, accountType: string, accountNumber: string, amount: number) => {
        dispatch(setBillingAccountSelectionModalOpen(false));
        dispatch(setAccountType(accountType));
        dispatch(setAccountNumber(accountNumber));
        dispatch(setAmount(amount));
        history.push(`/payment?accountNumber=${accountNumber}&accountType=${accountType}&amount=${amount}`);
    }
    
    useEffect(() => {
        if (!billingHistory) {
            dispatch(loadBillingHistory(true));
            // Refresh the page every 3 minutes to ensure infosend links don't expire
            setInterval(() => dispatch(loadBillingHistory(false)), 180000);
            dispatch(loadPaymentHistory());
        }
    }, [dispatch, billingHistory]);

    useEffect(() => {
        firebaseService.analytics().logEvent('view_billing_page', {
            platform: 'web'
        })
    }, [authUser]);

    return (
        <PageContainer className={styles.pageContainer}>
            {showAddBillingHelp ? (
                <PanelRow>
                    <Panel>
                        <div className={styles.billPayAddPaymentContainer}>
                            <h3 className={styles.billPayHeaderV2}>{t('Dashboard.BillPay')}</h3>
                            <div className={styles.billPayAddPaymentActionV2}>
                                <div>
                                    <NavLink to={`/account/${authUser?.Id}`}>
                                        {t('Dashboard.BillPayNoAccounts')}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </Panel>
                </PanelRow>
            ) : (
                <>
                    <BillPayAccountSelection
                        modalOpen={billingAccountSelectionModalOpen}
                        onClose={() => dispatch(setBillingAccountSelectionModalOpen(false))}
                        onSelect={handleOnPayBillsSelection}
                        paymentHistory={paymentHistory}
                        paymentHistoryV2={null}
                    />
                    {delinquentStatuses &&
                        <>
                            {delinquentStatuses.map((status, index) => {
                                if (status.amount && status.date) {
                                    return (
                                        <div className={styles.warningDelinquencyBanner} key={index}>
                                            <i className={`far fa-exclamation-triangle ${styles.warningDelinquencyBannerIcon}`}></i>
                                            <div>
                                                <p className={styles.warningDelinquencyBannerHeader}>{t('Payment.DelinquencyNoticeTitle', {status: status.type})}</p>
                                                <p className={styles.warningDelinquencyBannerBody}>{t('Payment.DelinquencyNoticeBody', {date: status.date, amount: status.amount?.toFixed(2)})}</p>
                                            </div>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </>
                    }


                    <div className="headerContainer">
                        <h2>{t('SideBar.Payment')}</h2>
                    </div>
                    <PanelRow>
                        <Panel>
                            <div className={styles.topPanelContainer}>
                                <div className={styles.blueHeading}>
                                    <p className={styles.paymentAccountText}>{t('Payment.Account')}: {t('Payment.Utility')}</p>
                                    <p className={styles.totalDueText}>{t('Payment.TotalDue')}: ${totalAmountDue.toFixed(2)}</p>
                                    {delinquentStatuses &&
                                        <>
                                            {delinquentStatuses.map((status, index) => {
                                                if (status.amount && status.date) {
                                                    return (
                                                        <p className={styles.delinquentAmountText} key={index}>{t('Payment.DelinquencyAmount', {type: status.type, amount: status.amount?.toFixed(2)})}</p>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </>
                                    }
                                </div>
                                <div className={styles.buttonContainer}>
                                    <div>
                                        <PayBillButton
                                            color='white'
                                            longerButton={true}
                                            onClick={() => navigateToPaymentHistory()}
                                        >
                                            {t('Payment.PaymentHistory')}
                                        </PayBillButton>
                                    </div>
                                    <div className={styles.buttonSpacer}>
                                        <PayBillButton
                                            color='midnightBlue'
                                            longerButton={true}
                                            onClick={() => handleOnPayBills()}
                                            disabled={!onlineBillPayEnabled}
                                        >
                                            {t('Dashboard.PayBill', { count: 2 })}
                                        </PayBillButton>
                                    </div>
                                </div>
                            </div>
                            {!onlineBillPayEnabled &&
                                <div className={styles.underButtonText}>
                                    {t('Alert.MaintenancePeriodWarningButtonMessage')}
                                </div>
                            }
                        </Panel>
                    </PanelRow>
                    <PanelRow>
                        <Panel>
                            <div className={styles.todaysDateContainer}>
                                <p className={styles.blueHeading}>{t('Utility.Today')}, {t(`Utility.${moment().format('MMMM')}`)} {moment().format('D')}</p>
                            </div>
                            <div className={styles.containerWeb}>
                                <Spinner isLoading={loadingPaymentInformation} />
                            </div>
                            <table className={styles.containerWeb}>
                                <colgroup>
                                    <col className={styles.colGroupLink} />
                                    <col className={styles.colGroupPrice} />
                                    <col className={styles.colGroupButtons} />
                                </colgroup>
                                <tbody>
                                    {billingHistory && billingHistory.map(record => {
                                        return (
                                            <WaterBillPaymentRecordDesktop 
                                                key={record.key}
                                                type={record.type}
                                                fullAmount={record.amountDueWithoutPending}
                                                currentAmount={`$${record.currentBalance.toFixed(2)}`}
                                                paymentDate={record.billDate}
                                                dueDate={record.dueDate}
                                                pdfLink={record.pdfLink}
                                                delinquentStatuses={delinquentStatuses}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div className={styles.containerMobile}>
                                <Spinner isLoading={loadingPaymentInformation} />
                                {billingHistory && billingHistory.map(record => {

                                    return (
                                        <WaterBillPaymentRecordMobile
                                            key={record.key}
                                            type={record.type}
                                            fullAmount={record.amountDueWithoutPending}
                                            currentAmount={`$${record.currentBalance.toFixed(2)}`}
                                            paymentDate={record.billDate}
                                            dueDate={record.dueDate}
                                            pdfLink={record.pdfLink}
                                            delinquentStatuses={delinquentStatuses}
                                        />
                                    );
                                })}
                            </div>
                        </Panel>
                    </PanelRow>
                </>
            )}
        </PageContainer>
    )
}
