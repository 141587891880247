import React, { useState } from 'react';
import ReactTable from 'react-table-6';
import { Popup } from 'semantic-ui-react';

type DelinquencyModalProps = {
    selectedFileContent: any[];
    onClose: () => void;
};

export const DelinquencyModal = (props: DelinquencyModalProps) => {
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [totalPages, setTotalPages] = useState(
        Math.ceil(props.selectedFileContent.length / pageSize),
    );

    const columns: Array<{
        id: string;
        Header: any;
        accessor: string | ((d: any) => any);
        minWidth?: number;
        maxWidth?: number;
        width?: number;
        sortable?: boolean;
        resizable?: boolean;
        Cell?: any;
    }> = [
        {
            id: 'AccountNumber',
            Header: () => {
                return (
                    <span>
                        <span>Account Number</span>
                    </span>
                );
            },
            accessor: 'accountNumber',
            width: 150,
        },
        {
            id: 'Delinquent Amount',
            Header: () => {
                return (
                    <span>
                        <span>Delinquent Amount</span>
                    </span>
                );
            },
            accessor: 'delinquentAmount',
            width: 150,
        },
        {
            id: 'Shutoff Date',
            Header: () => {
                return (
                    <span>
                        <span>Shutoff Date</span>
                    </span>
                );
            },
            accessor: 'shutoffDate',
            width: 150,
        },
        {
            id: 'phonePreference',
            Header: () => {
                return (
                    <span>
                        <span>Phone</span>
                    </span>
                );
            },
            accessor: 'phonePreference',
            Cell: (row: any) => {
                return row.value === true ? 'Yes' : 'No';
            },
            width: 78,
        },
        {
            id: 'userName',
            Header: () => {
                return (
                    <span>
                        <span>User</span>
                    </span>
                );
            },
            Cell: (row: any) => {
                if (row.value.hasPortalAccount === false) {
                    return <p>N/A</p>;
                }

                return (
                    <div>
                        <Popup
                            hoverable
                            content={
                                <div>
                                    <p>{row.value.userName}</p>
                                    <p>{row.value.userId}</p>
                                    <p>{row.value.userEmail}</p>
                                    <p>{row.value.userPhone}</p>
                                </div>
                            }
                            trigger={
                                <p
                                    onClick={() => {
                                        window.open(
                                            `/users/view/${row.value.userId}/users`,
                                            '_blank',
                                        );
                                    }}
                                >
                                    {row.value.userName}
                                </p>
                            }
                        />
                    </div>
                );
            },
            accessor: 'user',
            width: 200,
        },
        {
            id: 'address',
            Header: () => {
                return (
                    <span>
                        <span>Address</span>
                    </span>
                );
            },
            accessor: 'address',
            Cell: (row: any) => {
                return (
                    <Popup
                        hoverable
                        content={row.value}
                        trigger={<p>{row.value}</p>}
                    />
                );
            },
            width: 225,
        },
    ];

    const getData = () => {
        const data = props.selectedFileContent.slice();
        return data.splice(pageIndex * pageSize, pageSize);
    };

    return (
        <div>
            <ReactTable
                manual // indicates that we do the paging/sorting on the api-side
                data={getData()}
                pages={totalPages}
                loading={false}
                columns={columns}
                page={pageIndex}
                resizable={false}
                pageSize={pageSize}
                pageSizeOptions={[10, 20, 50, 100]}
                getTdProps={(
                    state: any,
                    rowInfo: any,
                    column: any,
                    instance: any,
                ) => {
                    if (rowInfo) {
                        return {
                            style: {
                                paddingTop: 20,
                                paddingBottom: 20,
                                cursor: 'pointer',
                            },
                            // Overwrite click functionality of table rows
                            onClick: (e: any, handleOriginal: any) => {
                                console.log('CLICKED');
                            },
                        };
                    }
                    return {
                        style: {
                            paddingTop: 20,
                            paddingBottom: 20,
                        },
                    };
                }}
                getTrGroupProps={(state: any, rowInfo: any, column: any) => {
                    if (rowInfo && rowInfo.index !== pageSize - 1) {
                        return {
                            style: {
                                borderBottom: 'solid 1px #AAAAAA',
                            },
                        };
                    }
                    return {
                        style: {},
                    };
                }}
                getTrProps={(state: any, rowInfo: any, column: any) => {
                    return {
                        style: {
                            color: '#1a3567',
                            fontWeight: 'bold',
                            textAlign: 'left',
                        },
                    };
                }}
                getTheadThProps={(state: any, rowInfo: any, column: any) => {
                    return {
                        style: {
                            backgroundColor: '#F8F8F8',
                            color: '#3b3b3b',
                            fontWeight: 'bold',
                            borderColor: 'transparent',
                            textAlign: 'left',
                            paddingBottom: 10,
                            paddingTop: 10,
                        },
                    };
                }}
                getTableProps={() => {
                    return {
                        style: {
                            border: 'none',
                        },
                    };
                }}
                getPaginationProps={(obj: any) => {
                    return {
                        style: {
                            background: 'white',
                        },
                    };
                }}
                onPageChange={(pageIndex) => {
                    setPageIndex(pageIndex);
                }}
                onPageSizeChange={(pageSize) => {
                    setPageSize(pageSize);
                    setTotalPages(
                        Math.ceil(props.selectedFileContent.length / pageSize),
                    );
                }}
                className="-highlight" // when hovering, highlight user
            ></ReactTable>
        </div>
    );
};
