import React, { useEffect } from 'react';
import {  useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './AllServices.module.scss';
import { useAppStateActivitiesWizardSelector, clearSelection } from '../activitiesWizard/activitiesWizardSlice';
import { loadActivities } from '../activitiesWizard/activitiesWizardActionCreators';
import { useAuthSelector } from '../../features/auth/authSlice';
import { Activity } from '../../features/activities/list/activitiesListAppState';
import { PageContainer } from '../pageContainer/PageContainer';
import { BackArrowDarkBlue } from '../backArrow/BackArrowDarkBlue';
import { personalOrganizationKey } from '../../constants';
import { DropdownOptions } from '../dropdown/Dropdown';
import { Spinner } from '../spinner/Spinner';
import { customFormMap, navigateToActivity } from '../../services/activityService';
import { firebaseService } from '../../services/firebaseService';

export const AllServices = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const {
        items,
        actions
    } = useAppStateActivitiesWizardSelector(state => state);
    const { authUser, selectedOrganization } = useAuthSelector(state => state);
    const { loadingActivities } = useAppStateActivitiesWizardSelector(state => state);

    useEffect(() => {
        dispatch(clearSelection(null));
        if (!items || !items.length) {
            dispatch(loadActivities(authUser?.Id, 'all'));
        }
    }, [dispatch, authUser, items]);

    let handleOnStart = (selectedItem: Activity) => {
        firebaseService
            .app()
            .analytics()
            .logEvent('all_services_click', {
                service: selectedItem.Item,
                platform: 'web'
            });

        if (!authUser && selectedItem.Id.toUpperCase() === customFormMap.WATER_BILL.toUpperCase()) {
            history.push(`/login?redirectto=payment&guest_enabled=true`);
            return;
        }

        if(!authUser && selectedItem.Id.toUpperCase() === customFormMap.QUICK_PERMIT.toUpperCase()) {
            history.push(`/login`);
            return;
        }

        if (!authUser?.Email && !selectedItem.GuestEnabled) {
            history.push(`/login?activityId=${selectedItem.Id}`);
            return;
        }

        const { historyPushLocation, dispatchFunction } = navigateToActivity(selectedItem as Activity);

        if (dispatchFunction) dispatch(dispatchFunction);
        if (historyPushLocation) history.push(historyPushLocation);
    }

    const displayActionImg = (action: string) => {
        switch (action) {
            case 'APPLY_FOR':
                return styles.applyActionImg;
            case 'FIND':
                return styles.findActionImg;
            case 'PAY_FOR':
                return styles.payActionImg;
            case 'REGISTER_FOR':
                return styles.registerActionImg;
            case 'REPORT':
                return styles.reportActionImg;
            default:
                return styles.defaultActionImg;
        }
    }

    const displayActionText = (action: DropdownOptions) => {
        switch (action.value) {
            case 'REPORT':
                return t('Activity.ReportAnIssue');
            case 'APPLY_FOR':
                return t('Activity.Apply');
            case 'REGISTER_FOR':
                return t('Activity.Register');
            case 'PAY_FOR':
                return t('Activity.Pay');
            default:
                return action.text;
        }
    }

    const displayItemText = (item: DropdownOptions) => {
        if (item.item?.Action === 'APPLY_FOR') {
            return t('Activity.ApplyForA') + ' ' + t(item.translation!);
        }
        if (item.key === 'WATER_BILL') {
            return t('Activity.PayForWaterBill');
        }

        return t(item.translation!);
    }

    const filteredActions = selectedOrganization === personalOrganizationKey ?
        actions :
        actions.filter(action => {
            return action.key !== 'REPORT';
        });
    const translatedActions = filteredActions.map(a => (
        { ...a, text: t(`Activity.Action${a.value}`)}
    ));
    const reportAction = translatedActions.find(a => a.key === 'REPORT');

    return (
        <>
        {
            loadingActivities ?
            <PageContainer className={styles.topInnerPanelContainer} style={authUser ? {marginTop: '0px'} : {}}>
                <Spinner isLoading={loadingActivities} />
            </PageContainer>
            :
            <PageContainer className={styles.topInnerPanelContainer} style={authUser ? {marginTop: '0px'} : {}}>
                <div className={!authUser ? styles.backToHomepageArrow : ''} style={{ width: 'fit-content' }}>
                    <BackArrowDarkBlue url="/" text={t('Activity.BackToHomepage')} />
                </div>
                <div className={`${styles.actionOuterContainer} ${!authUser ? styles.nonAuthUserContainer : styles.authUserContainer}`}>
                    <div className={styles.actionInnerContainer}>
                        {translatedActions.map((action, i) => {
                            if (action.value !== 'REPORT') {
                                return (
                                    <div className={styles.actionContainer} key={i}>
                                        <div className={styles.actionContainerHeader}>
                                            <div className={`${styles.actionImg} ${displayActionImg(action.value)}`}></div>
                                            <h1 className={styles.title}>{displayActionText(action)}</h1>
                                        </div>
                                        {
                                            items.map((item, i) => {
                                                if (item.item?.Action === action.value) {
                                                    return (
                                                        <div key={`action-${i}`} style={{ marginBottom: '20px' }}>
                                                            <span onClick={() => handleOnStart(item.item!)} className={styles.linkText}>{displayItemText(item) + ' >'}</span>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })
                                        }
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                    <div className={styles.actionInnerContainer}>
                        {reportAction &&
                            <div className={styles.actionContainer}>
                                <div className={styles.actionContainerHeader}>
                                    <div className={`${styles.actionImg} ${displayActionImg(reportAction.value)}`}></div>
                                    <h1 className={styles.title}>{displayActionText(reportAction)}</h1>
                                </div>
                                {
                                    items.map((item, i) => {
                                        if (item.item?.Action === reportAction.value) {
                                            return (
                                                <div key={`action-${i}`} style={{ marginBottom: '20px' }}>
                                                    <span onClick={() => handleOnStart(item.item!)} className={styles.linkText}>{displayItemText(item) + ' >'}</span>
                                                </div>
                                            );
                                        }
                                        return null;
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            </PageContainer>
        }
        </>
    );
}
