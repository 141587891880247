import React, { useCallback, useEffect, useState } from 'react';
import { axios } from '../../services/axiosService';

export interface CoreBillPayFormProps {
    html?: string;
    afterToken?: string;
    fullToken?: string;
    transactionType?: string;
    amount?: string;
    accountNumber?: string;
    appCode?: string;
    paymentType?: string;
    activityId?: string;
    unauth?: boolean;
    english?: boolean;
    processEvent?: (data: any) => void;
}

export const CoreBillPayForm = React.memo((props: CoreBillPayFormProps) => {
    const [timer, setTimer] = useState<any>();
    const [html, setHtml] = useState<string>();

    const updateIFrame = useCallback((iframe: HTMLIFrameElement) => {
        if (iframe) {
            // find position of element and set that to the iframe height
            const iframeHeight = iframe.contentDocument
                ?.getElementById('eGov_functionComplete')
                ?.getBoundingClientRect().top;

            if (iframeHeight) {
                iframe.style.height = iframeHeight + 60 + 'px';
            }
        }
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const iframe = document.getElementById(
                    'billPayIframe',
                ) as HTMLIFrameElement;
                if (iframe) {
                    if (props.html) {
                        iframe.srcdoc = props.html;
                    } else if (!html) {
                        try {
                            const params = {
                                transactionType: props.transactionType,
                                accountNumber: props.accountNumber,
                                english: props.english,
                            } as {
                                transactionType: string;
                                accountNumber: string | null;
                                amount?: string | number;
                                appCode?: string;
                                activityId?: string;
                                english?: boolean;
                            };
                            if (props.amount) {
                                params.amount = props.amount;
                            }
                            if (props.appCode) {
                                params.appCode = props.appCode;
                            }
                            if (props.activityId) {
                                params.activityId = props.activityId;
                            }
                            params.english = (localStorage.getItem('language') !== 'es');
                            const url = props.unauth
                                ? '/core/bill-pay/iframe/unauth'
                                : '/core/bill-pay/iframe';
                            const result = (await axios.get(url, { params }))
                                .data as string;
                            setHtml(result);
                            iframe.srcdoc = result;
                        } catch (err) {
                            console.error(err);
                        }
                    }

                    // create timer to check for iframe content changes
                    if (!timer) {
                        setTimer(
                            setInterval(() => {
                                if (iframe.contentDocument) {
                                    updateIFrame(iframe);
                                }
                            }, 1),
                        );
                    }

                    iframe.onload = () => {
                        updateIFrame(iframe);
                        window.addEventListener('message', (event) => {
                            if (props.processEvent) {
                                props.processEvent(event.data);
                            }
                        });
                    };
                }
            } catch (err) {
                console.error(err);
            }
        })();
    }, [props, updateIFrame, timer, html]);

    return (
        <>
            <iframe
                style={{ border: 'none', width: '100%', minHeight: '500px' }}
                title="Bill Pay Form"
                id="billPayIframe"
                scrolling="no"
            ></iframe>
        </>
    );
});
