import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { buildSubStateSelector } from '../../store/utilities/useAppStateSelector';
import { BillMaintenanceAppState } from './billMaintenanceAppState';

export const slice = createSlice<BillMaintenanceAppState, SliceCaseReducers<BillMaintenanceAppState>>({
    name: 'billMaintenancePeriods',
    initialState: {
        userBillMaintenancePeriod: undefined,
        billMaintenancePeriods: [],
        onlineBillPayEnabled: true,
        loading: false,
        addModalOpen: false,
        editModalOpen: [],
        deleteModalOpen: [],
        displayUserBillMaintenanceRecords: false,
    },
    reducers: {
        setUserBillMaintenancePeriod: (state, action) => {
            state.userBillMaintenancePeriod = action.payload;
        },
        setBillMaintenancePeriods: (state, action) => {
            state.billMaintenancePeriods = action.payload;
        },
        setOnlineBillPayEnabled: (state, action) => {
            state.onlineBillPayEnabled = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setAddModalOpen: (state, action) => {
            state.addModalOpen = action.payload;
        },
        setEditModalOpen: (state, action) => {
            state.editModalOpen = action.payload;
        },
        setDeleteModalOpen: (state, action) => {
            state.deleteModalOpen = action.payload;
        },
        setDisplayUserBillMaintenanceRecords: (state, action) => {
            state.displayUserBillMaintenanceRecords = action.payload;
        },
    }
});

export const useAppStateBillMaintenancePeriodSelector = buildSubStateSelector<BillMaintenanceAppState>(state => state.billMaintenancePeriods);
export const {
    setUserBillMaintenancePeriod,
    setBillMaintenancePeriods,
    setOnlineBillPayEnabled,
    setLoading,
    setAddModalOpen,
    setEditModalOpen,
    setDeleteModalOpen,
    setDisplayUserBillMaintenanceRecords,
} = slice.actions;
export const billMaintenancePeriodsReducer = slice.reducer;
