import { axios } from '../../services/axiosService';
import { AnyAction, Dispatch } from 'redux';
import { Alert } from './systemAlertsAppState';
import { setAlerts, setDeleteAlertModalOpen, setEditAlertModalOpen, setFocusedAlert, setLoading, setUnreadAlertIndex, setUserStoredAlerts } from './systemAlertsListSlice';
import { openMessageModal } from '../../components/statusModal/messageModalActionCreators';
import { BillMaintenancePeriod } from './billMaintenanceAppState';
import { setOnlineBillPayEnabled, setUserBillMaintenancePeriod, setLoading as setLoadingMaintenancePeriod } from './billMaintenancePeriodsSlice';
import { TFunction } from 'i18next';

export const loadAlerts = () => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        const alerts = (await axios.get('/systemAlerts')).data as Alert[];
        dispatch(setAlerts(alerts));
        dispatch(setEditAlertModalOpen(new Array(alerts.length).fill(false)));
        dispatch(setDeleteAlertModalOpen(new Array(alerts.length).fill(false)));
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
};

export const sortAlerts = (alertArray: Alert[]): Alert[] => {
    return alertArray.sort((a: Alert, b: Alert) => {
        if (a.Active !== b.Active) {
          return a.Active ? -1 : 1;
        }
        if (a.Read !== b.Read) {
          return a.Read ? 1 : -1;
        }
        const dateB: any = b.CreatedAt;
        const dateA: any = a.CreatedAt;
        return dateB - dateA;
    });
}

// Check next active, unread alert
export const checkNextUnreadIndex = (alertArray: Alert[]): number => {
    let unreadIndex = -1;
    for(let i = 0; i < alertArray.length; i++) {
        const alert = alertArray[i];

        if (alert.Active) {
            unreadIndex = i;
            break;
        }
    }
    return unreadIndex;
}

export const loadUserAlerts = (t: TFunction) => async (dispatch: Dispatch) => {
    try {
        // Load online bill maintenance alerts
        dispatch(setLoading(true));
        dispatch(setLoadingMaintenancePeriod(true));

        const userPaymentMaintenanceInfo = (await axios.get('/paymentMaintenanceInfo')).data;
        const billingMaintenancePeriod: BillMaintenancePeriod = {
            id: userPaymentMaintenanceInfo.maintenanceWarningAlert?.id,
            maintenanceWarningAlert: userPaymentMaintenanceInfo.maintenanceWarningAlert,
            maintenanceSettings: userPaymentMaintenanceInfo.maintenanceSettings,
        }
        dispatch(setUserBillMaintenancePeriod(billingMaintenancePeriod));
        dispatch(setOnlineBillPayEnabled(userPaymentMaintenanceInfo.onlineBillPayEnabled));
        dispatch(setLoadingMaintenancePeriod(false));

        let maintenanceWarningAlert: Alert | undefined = undefined;
        if (billingMaintenancePeriod.maintenanceWarningAlert) {
            maintenanceWarningAlert = {
                Id: billingMaintenancePeriod.id!.toString(),
                CreatedBy: '',
                TitleAlertEnglish: t('Alert.MaintenanceWarningTitle'),
                TitleAlertSpanish: t('Alert.MaintenanceWarningTitle'),
                TextEnglish: '<p>' + billingMaintenancePeriod.maintenanceWarningAlert.englishText + '</p>',
                TextSpanish: '<p>' + billingMaintenancePeriod.maintenanceWarningAlert.spanishText + '</p>',
                Active: true,
                CreatedAt: new Date().toISOString(),
                MaintenanceWarning: true,
            };
        }

        // Load all other alerts from API
        const alerts = (await axios.get('/systemAlerts')).data as Alert[];

        // Merge billing maintenance alerts with other alerts
        const combinedAlerts = alerts;
        if (maintenanceWarningAlert) {
            combinedAlerts.push(maintenanceWarningAlert);
        }

        // Dispatch merged alerts
        dispatch(setAlerts(combinedAlerts));
        dispatch(setEditAlertModalOpen(new Array(combinedAlerts.length).fill(false)));
        dispatch(setDeleteAlertModalOpen(new Array(combinedAlerts.length).fill(false)));

        let arrCopy: Alert[] = combinedAlerts.map(alert => ({
            ...alert,
            Read: false,
        }));

        arrCopy = sortAlerts(arrCopy);
        dispatch(setUserStoredAlerts(arrCopy));

        if (arrCopy.length > 0 && arrCopy[0].Active) {
            dispatch(setUnreadAlertIndex(0));
        } else {
            dispatch(setUnreadAlertIndex(-1));
        }
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
        dispatch(setLoadingMaintenancePeriod(false));
    }
};

export const storeUserAlertsArray = (alerts: Alert[]) => async (dispatch: Dispatch) => {
    try {
        sessionStorage.setItem('systemAlertArray', JSON.stringify(alerts));
        dispatch(setUserStoredAlerts(alerts));
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
};

export const loadAlert = (id: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        const alert = (await axios.get(`/systemAlerts/${id}`));
        dispatch(setFocusedAlert(alert));
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
};

export const createAlert = (titleEnglish: string, titleSpanish: string, textEnglish: string, textSpanish: String) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        (await axios.post(`/systemAlerts`, {
            titleEnglish,
            titleSpanish,
            textEnglish,
            textSpanish,
        }));
        await loadAlerts()(dispatch);
        // Display success message that alert was created
        dispatch(openMessageModal('add-system-alert', 'success') as unknown as AnyAction);
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
};

export const updateAlert = (id: string,
    titleEnglish: string,
    titleSpanish: string,
    textEnglish: string,
    textSpanish: string,
    active: boolean) => async (dispatch: Dispatch) => {
        try {
            dispatch(setLoading(true));
            (await axios.put(`/systemAlerts/${id}`, {
                titleEnglish,
                titleSpanish,
                textEnglish,
                textSpanish,
                active
            }));
            await loadAlerts()(dispatch);
            // Display success message that alert was updated
            dispatch(openMessageModal('update-system-alert', 'success') as unknown as AnyAction);
        } catch (err) {
            dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
            console.error(err);
        } finally {
            dispatch(setLoading(false));
        }
    };

export const deleteAlert = (id: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(setLoading(true));
        (await axios.delete(`/systemAlerts/${id}`));
        await loadAlerts()(dispatch);
        // Display success message that alert was deleted
        dispatch(openMessageModal('delete-system-alert', 'success') as unknown as AnyAction);
    } catch (err) {
        dispatch(openMessageModal(null, 'error') as unknown as AnyAction);
        console.error(err);
    } finally {
        dispatch(setLoading(false));
    }
};
