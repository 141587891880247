import React, { useEffect } from 'react';
import styles from './SystemAlerts.module.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PageContainer } from '../../components/pageContainer/PageContainer';
import { PayBillButton } from '../../components/button/PayBillButton';
import { BillMaintenanceModal } from './BillMaintenanceModal';
import {
    setAddModalOpen,
    setDeleteModalOpen,
    setEditModalOpen,
    useAppStateBillMaintenancePeriodSelector,
} from './billMaintenancePeriodsSlice';
import { BillMaintenancePeriod } from './billMaintenanceAppState';
import { loadAdminBillMaintenancePeriods } from './billMaintenanceActionCreators';

interface TableProps {
    periods: BillMaintenancePeriod[];
}

export function BillMaintenanceRecordsTable({ periods }: TableProps) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { editModalOpen, deleteModalOpen } =
        useAppStateBillMaintenancePeriodSelector((state) => state);

    function editAlert(index: number) {
        const arrCopy: boolean[] = [...editModalOpen];
        arrCopy.splice(index, 1, true);
        dispatch(setEditModalOpen(arrCopy));
    }

    function deleteAlert(index: number) {
        const arrCopy: boolean[] = [...deleteModalOpen];
        arrCopy.splice(index, 1, true);
        dispatch(setDeleteModalOpen(arrCopy));
    }

    function formatActiveDays(daysActive: {
        everyDay: boolean;
        monday: boolean;
        tuesday: boolean;
        wednesday: boolean;
        thursday: boolean;
        friday: boolean;
        saturday: boolean;
        sunday: boolean;
    }) {
        if (daysActive.everyDay) return t('Alert.EveryDay');

        const activeDays: string[] = [];
        if (daysActive.monday) activeDays.push(t('Alert.M'));
        if (daysActive.tuesday) activeDays.push(t('Alert.T'));
        if (daysActive.wednesday) activeDays.push(t('Alert.W'));
        if (daysActive.thursday) activeDays.push(t('Alert.Th'));
        if (daysActive.friday) activeDays.push(t('Alert.F'));
        if (daysActive.saturday) activeDays.push(t('Alert.Sa'));
        if (daysActive.sunday) activeDays.push(t('Alert.Su'));

        return activeDays.length > 0
            ? activeDays.join(', ')
            : t('Alert.NoActiveDays');
    }

    return (
        <div className={styles.tableContainer}>
            <table>
                <tr className={styles.tableHeader}>
                    <th className={styles.active}>{t('Alert.Enabled')}</th>
                    <th className={styles.alertEnglish}>
                        {t('Alert.EnglishText')}
                    </th>
                    <th className={styles.alertSpanish}>
                        {t('Alert.SpanishText')}
                    </th>
                    <th className={styles.timestamp}>
                        {t('Alert.ActiveTimes')}
                    </th>
                    <th className={styles.actions}>{t('Alert.Actions')}</th>
                </tr>
                {periods?.map((period, index) => (
                    <tr
                        className={`listItemColor ${styles.item}`}
                        key={period.id}
                    >
                        <td className={styles.active}>
                            {period.maintenanceSettings!.enabled ? (
                                <div className={styles.greenCheckmark}>
                                    <i className="far fa-check fa-2xl" />
                                </div>
                            ) : (
                                <div className={styles.redX}>
                                    <i className="far fa-times fa-2xl" />
                                </div>
                            )}
                        </td>
                        <td className={styles.billMaintenanceText}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: period?.maintenanceSettings!
                                        .englishText,
                                }}
                            />
                        </td>
                        <td className={styles.billMaintenanceText}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: period?.maintenanceSettings!
                                        .spanishText,
                                }}
                            />
                        </td>
                        <td className={styles.timestamp}>
                            {period.maintenanceSettings?.startDate && (
                                <>
                                    <b>
                                        {t('Alert.DateRangeScheduledPeriod')}:
                                    </b>
                                    <br />
                                    {period.maintenanceSettings?.startDate}
                                    {period.maintenanceSettings?.endDate && (
                                        <>
                                            {period.maintenanceSettings
                                                ?.startDate && (
                                                <span>
                                                    {' ' + t('Util.To') + ' '}
                                                </span>
                                            )}
                                            {
                                                period.maintenanceSettings
                                                    ?.endDate
                                            }
                                        </>
                                    )}
                                    <br />
                                    <br />
                                </>
                            )}
                            <b>{t('Alert.ScheduleRun')}:</b>
                            <br />
                            {period.maintenanceSettings!.startTime}
                            {' - '}
                            {period.maintenanceSettings!.endTime}
                            <br />
                            <span className={styles.daysActive}>
                                {formatActiveDays(
                                    period.maintenanceSettings!.daysActive,
                                )}
                            </span>
                        </td>
                        <td className={styles.actions}>
                            <i
                                className={`far fa-pencil fa-2xl ${styles.editIcon}`}
                                onClick={() => editAlert(index)}
                            />
                            <i
                                className={`far fa-trash fa-2xl ${styles.trashIcon}`}
                                onClick={() => deleteAlert(index)}
                            />
                        </td>
                        <BillMaintenanceModal
                            modalOpen={editModalOpen[index]}
                            billingPeriod={period}
                            index={index}
                        />
                        <BillMaintenanceModal
                            modalOpen={deleteModalOpen[index]}
                            billingPeriod={period}
                            index={index}
                        />
                    </tr>
                ))}
            </table>
        </div>
    );
}

export function BillMaintenanceSettings() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        billMaintenancePeriods = [],
        addModalOpen = false,
        onlineBillPayEnabled = true,
    } = useAppStateBillMaintenancePeriodSelector((state) => state);

    useEffect(() => {
        dispatch(loadAdminBillMaintenancePeriods());
    }, [dispatch]);

    return (
        <PageContainer>
            <BillMaintenanceModal
                modalOpen={addModalOpen}
                billingPeriod={null}
            />
            <div className={styles.billPayMaintenanceSettingsContainer}>
                <h1 className={styles.title}>
                    {t('Alert.MaintenanceSettings')}
                </h1>
                <div>
                    <PayBillButton
                        color="white"
                        longerButton={true}
                        onClick={() => dispatch(setAddModalOpen(true))}
                    >
                        <span>{t('Alert.AddMaintenancePeriod')}</span>
                    </PayBillButton>
                </div>
            </div>
            <div className={styles.action_container}>
                <h3 className={styles.currentBillPayStatus}>
                    {t('Alert.CurrentOnlineBillPayStatus')}
                    {': '}
                    {onlineBillPayEnabled ? (
                        <span className={styles.enabledText}>
                            {t('Alert.Enabled')}
                        </span>
                    ) : (
                        <span className={styles.disabledText}>
                            {t('Alert.Disabled')}
                        </span>
                    )}
                </h3>
                {!billMaintenancePeriods?.length ? (
                    <>
                        <div className={styles.item_text}>
                            <h3 className={styles.action_container_heading}>
                                {t('Alert.NoAlerts')}
                            </h3>
                        </div>
                    </>
                ) : (
                    <div className={styles.panelSpacing}>
                        <BillMaintenanceRecordsTable
                            periods={billMaintenancePeriods!}
                        />
                    </div>
                )}
            </div>
        </PageContainer>
    );
}
