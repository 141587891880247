import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {counterReducer} from '../features/counter/counterSlice';
import {forgotPasswordReducer} from '../features/forgotPassword/forgotPasswordSlice';
import {resetPasswordReducer} from '../features/resetPassword/resetPasswordSlice';
import {headerReducer} from '../components/header/headerSlice';
import {activitiesListReducer} from '../features/activities/list/activitiesListSlice';
import { activitiesCreateReducer } from '../features/activities/create/activitiesCreateSlice';
import { sideBarReducer } from '../components/sideBarMenu/sideBarSlice';
import {laserficheEmbedReducer} from '../features/laserfiche/embed/laserficheEmbedSlice';
import { activitiesEditReducer } from '../features/activities/edit/activitiesEditSlice';
import { authReducer } from '../features/auth/authSlice';
import { accountCreateReducer } from '../features/account/create/accountCreateSlice';
import { firebaseReducer, actionTypes } from "react-redux-firebase";
import { loginReducer } from '../features/login/loginSlice';
import { signupReducer } from '../features/signup/signupSlice';
import { languagesEditReducer } from '../features/languages/edit/languagesEditSlice';
import { permitFormReducer } from '../features/customForms/permitForm/permitFormSlice';
import { zoningInquiryReducer } from '../features/customForms/zoningInquiry/zoningInquirySlice';
import { activitiesWizardReducer } from '../components/activitiesWizard/activitiesWizardSlice';
import { dashboardReducer } from '../features/dashboard/dashboardSlice';
import { reportReducer } from '../features/customForms/report/reportSlice';
import { messagesReducer } from '../features/messages/messagesSlice';
import { paymentReducer } from '../features/payment/paymentSlice';
import { organizationManagementReducer } from '../features/organizationManagement/organizationManagementSlice';
import { addOrganizationReducer } from '../features/organizationManagement/add/addOrganizationSlice';
import { organizationSearchReducer } from '../components/organizationSearch/organizationSearchSlice';
import { verificationRequiredReducer } from '../features/account/verification/verificationRequiredSlice';
import { elginTodayReducer } from '../features/elginToday/elginTodaySlice';
import { accountEditReducer } from '../features/account/edit/accountEditSlice';
import { billingSettingsReducer } from '../features/account/billing/billingSettingsSlice';
import { inProgressReducer } from '../features/activities/view/activitiesViewSlice';
import { activitiesViewItemReducer } from '../features/activities/view/item/activitiesViewItemSlice';
import { searchReducer } from '../features/search/searchSlice';
import { salesforcePublicFieldsListReducer } from '../features/salesforcePublicFields/salesforcePublicFieldsSlice';
import { systemAlertListReducer } from '../features/systemAlerts/systemAlertsListSlice';
import { messageModalReducer } from '../components/statusModal/messageModalViewSlice';
import { featureFlagReducer } from '../features/featureFlags/featureFlagSlice';
import { garbageSearchReducer } from '../features/garbageSearch/garbageSearchSlice';
import { boardsAndCommissionsReducer } from '../features/boardsAndCommissions/boardsAndCommissionsSlice';
import { boardOrCommissionViewReducer } from '../features/boardsAndCommissions/view/boardOrCommissionViewSlice';
import { userTableReducer } from '../features/userAdminView/list/UserSearchV2Slice';
import { billMaintenancePeriodsReducer } from '../features/systemAlerts/billMaintenancePeriodsSlice';

export function configureAppStore() {
    const store = configureStore({
        reducer: {
            counter: counterReducer,
            forgotPassword: forgotPasswordReducer,
            resetPassword: resetPasswordReducer,
            header: headerReducer,
            activitiesList: activitiesListReducer,
            activitiesCreate: activitiesCreateReducer,
            sideBar: sideBarReducer,
            laserficheEmbed: laserficheEmbedReducer,
            activitiesEdit: activitiesEditReducer,
            auth: authReducer,
            accountCreate: accountCreateReducer,
            accountEdit: accountEditReducer,
            login: loginReducer,
            signup: signupReducer,
            firebase: firebaseReducer,
            languagesEdit: languagesEditReducer,
            permitForm: permitFormReducer,
            zoningInquiry: zoningInquiryReducer,
            activitiesWizard: activitiesWizardReducer,
            dashboard: dashboardReducer,
            report: reportReducer,
            messages: messagesReducer,
            payment: paymentReducer,
            organizationManagement: organizationManagementReducer,
            addOrganization: addOrganizationReducer,
            organizationSearch: organizationSearchReducer,
            verificationRequired: verificationRequiredReducer,
            elginToday: elginTodayReducer,
            billingSettings: billingSettingsReducer,
            inProgress: inProgressReducer,
            activitiesViewItem: activitiesViewItemReducer,
            search: searchReducer,
            salesforcePublicFields: salesforcePublicFieldsListReducer,
            systemAlertList: systemAlertListReducer,
            messageModal: messageModalReducer,
            featureFlag: featureFlagReducer,
            garbageSearch: garbageSearchReducer,
            boardsAndCommissions: boardsAndCommissionsReducer,
            boardOrCommissionView: boardOrCommissionViewReducer,
            userTable: userTableReducer,
            billMaintenancePeriods: billMaintenancePeriodsReducer,
        },
        middleware: getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [actionTypes.LOGIN]
            }
        })
    });
    return store
}