import React, { FunctionComponent } from 'react';
import styles from './Button.module.scss';
import { Button as SemanticButton } from 'semantic-ui-react';

export interface ButtonProps {
    color?: 'midnightBlue' | 'white' | 'whiteUnavailable' | 'red';
    fluid?: boolean;
    disabled?: boolean;
    onClick?(): void;
    style?: object;
    loading?: boolean;
    type?: string;
    longerButton?: boolean;
    underButtonText?: string;
}

export const PayBillButton: FunctionComponent<ButtonProps> = ({
    children,
    color,
    fluid,
    disabled,
    style,
    longerButton,
    onClick,
    underButtonText,
    ...rest
}) => {
    let getColor = () => {
        switch (color) {
            case 'midnightBlue':
                return styles.midnightBlue;
            case 'white':
                return styles.white;
            case 'red':
                return styles.red;
            case 'whiteUnavailable':
                return styles.whiteUnavailable;
        }
    };
    return (
        <>
            <SemanticButton
                className={`${
                    longerButton ? styles.longerPayBillButton : styles.payBillButton
                } ${getColor()} ${style}`}
                fluid={fluid}
                disabled={disabled}
                onClick={onClick}
                {...rest}
            >
                {children}
            </SemanticButton>
            {underButtonText && (
                <div className={styles.underButtonText}>{underButtonText}</div>
            )}
        </>
    );
};

PayBillButton.defaultProps = {
    color: 'midnightBlue',
};
