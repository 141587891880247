import React, { FunctionComponent } from 'react';
import styles from './MessageModalButton.module.scss';
import { Button as SemanticButton } from 'semantic-ui-react';

export interface ButtonProps {
    color?: string;
    fluid?: boolean;
    disabled?: boolean;
    onClick?(): void;
    style?: string;
    loading?: boolean;
    type?: string;
    biggerFont?: boolean;
    underButtonText?: string;
}

export const Button: FunctionComponent<ButtonProps> = ({
    children,
    color,
    fluid,
    disabled,
    style,
    biggerFont,
    onClick,
    underButtonText,
    ...rest
}) => {
    let getColor = () => {
        switch (color) {
            case 'green':
                return styles.green;
            case 'limegreen':
                return styles.limegreen;
            case 'blue':
                return styles.blue;
            case 'red':
                return styles.red;
            case 'lightBlue':
                return styles.lightBlue;
            case 'darkBlue':
                return styles.darkBlue;
            case 'orange':
                return styles.orange;
            case 'black':
                return styles.black;
            case 'gray':
                return styles.gray;
            default:
                return styles.lightBlue;
        }
    };

    return (
        <>
            <SemanticButton
                className={`${styles.button} ${getColor()} ${style}`}
                fluid={fluid}
                disabled={disabled}
                onClick={onClick}
                {...rest}
            >
                {children}
            </SemanticButton>
            {underButtonText && <div className={styles.underButtonText}>{underButtonText}</div>}
        </>
    );
};

Button.defaultProps = {
    color: 'blue',
    style: '',
};
